import { Button, Input, Spin } from "antd";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiTienda } from "../../API/ConfigApi";
import FooterAdmin from "../../components/FooterAdmin";
import {
  InputNumberT,
  InputPasswordT,
  InputT,
} from "../../components/FormularioTeclas";

function SendPassword(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "300px",
            display: "flex",
            flexDirection: "column",
            padding: "25px 20px",
            borderRadius: "10px",
            border: "1px solid #D8D8D8",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: 900,
              fontSize: "30px",
              color: "#7d07f3",
            }}
          >
            Ingresa la nueva contraseña
          </h2>

          <form onSubmit={handleSubmit(props.sendNewPassword)}>
            <InputPasswordT
              name="passwordCellphone"
              status={
                errors?.passwordCellphone?.type === "required" ||
                errors?.passwordCellphone?.type === "minLength"
                  ? "error"
                  : ""
              }
              control={control}
              rules={{
                required: true,
                minLength:"8"
              }}
              borderRadius={"7px"}
            ></InputPasswordT>
            {errors?.passwordCellphone?.type === "required" && (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span style={{ color: "red" }}>Campo requerido</span>
              </div>
            )}
            {errors?.passwordCellphone?.type === "minLength" && (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span style={{ color: "red" }}>
                  La contraseña debe ser mayor a 8 caracteres
                </span>
              </div>
            )}

            <div>
              <Button
                style={{
                  width: "100%",
                  margin: "15px 0 0 0",
                  borderRadius: "7px",
                  backgroundColor: "#7d07f3",
                  color: "#fff",
                }}
                htmlType="submit"
                loading={props.loadingAction.loadingPassword}
              >
                Cambiar contraseña
              </Button>
            </div>
          </form>
        </div>
      </div>

      <FooterAdmin />
    </div>
  );
}

export default SendPassword;

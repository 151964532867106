import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Modal, notification, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import DiscountsByProductList from "./DiscountsByProductList";
import {
  CheckboxT,
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
  TextAreaT,
} from "../../components/FormularioTeclas";
import { ApiTienda } from "../../API/ConfigApi";
import moment from "moment";

function ProductItem(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [typeEventModal, setTypeEventModal] = useState(false);

  const [isModalInventaryOpen, setIsModalInventaryOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const {
    handleSubmit: handleSubmitInventary,
    control: controlInventary,
    reset: resetInventary,
    getValues: getValuesInventary,
    setValue: setValueInventary,
    formState: { errors: errorsInventary },
    watch: watchInventary,
  } = useForm();

  const onChangeSalesPriceByValuePricePerUnit = async (valuePricePerUnit) => {
    if (valuePricePerUnit && typeof valuePricePerUnit === "number") {
      if (
        getValues("profitPercent") &&
        typeof getValues("profitPercent") === "number"
      ) {
        let profit = valuePricePerUnit * (getValues("profitPercent") / 100);
        let salePrice = Math.ceil((valuePricePerUnit + profit) / 50) * 50;
        setValue("salePrice", salePrice);
      }
    }
  };

  const onChangeSalesPriceByProfitPercent = async (valueProfitPercent) => {
    if (valueProfitPercent && typeof valueProfitPercent === "number") {
      if (
        getValues("pricePerUnity") &&
        typeof getValues("pricePerUnity") === "number"
      ) {
        let profit = getValues("pricePerUnity") * (valueProfitPercent / 100);
        let salePrice =
          Math.ceil((getValues("pricePerUnity") + profit) / 50) * 50;
        setValue("salePrice", salePrice);
      }
    }
  };

  const showModal = (dataProduct) => {
    if (dataProduct) {
      reset({
        ...dataProduct,
        estimatedPrice: dataProduct.profitPercent === 0 ? false : true,
        pricePerUnity: dataProduct.unitPrice,
      });
    }

    setIsModalOpen(true);
  };
  const deleteProduct = (idProduct) => {
    Modal.confirm({
      title: "¿Estas seguro de eliminar este producto?",
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        ApiTienda(
          "products",
          {
            id: idProduct,
          },
          "deleteProduct"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Producto eliminado",
            });
          } else {
            notification["error"]({
              message: "No se pudo eliminar el producto",
            });
          }
          props.loadProducts(props.currentPage);
        });
      },
    });
  };

  const editProduct = (valuesProduct) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editProductLoading: true,
    }));

    ApiTienda(
      "products",
      {
        product: {
          image: valuesProduct.image,
          name: valuesProduct.name,
          unitPrice: valuesProduct.pricePerUnity,
          estimatedPrice: valuesProduct.estimatedPrice,
          salePrice: valuesProduct.salePrice,
          profitPercent: valuesProduct.profitPercent,
          description: valuesProduct.description,
          category: valuesProduct.category,
          quantity: valuesProduct.quantity,
          status: valuesProduct.status,
          keyWords: valuesProduct.keyWords,
          expirationDate: moment(valuesProduct.expirationDate).format(
            "YYYY-MM-DD"
          ),
        },
        id: valuesProduct._id,
      },
      "updateProduct"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Producto actualizado",
        });
      } else {
        notification["error"]({
          message: "No se pudo editar el producto",
        });
      }

      props.loadProducts(props.currentPage);
      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editProductLoading: false,
      }));
      setIsModalOpen(false);
    });
  };

  const editInventaryOfProduct = (valuesInventary) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editInventaryOfProductLoading: true,
    }));
    if (valuesInventary.field && valuesInventary.valueOfQuantity) {
      ApiTienda(
        "products",
        {
          newAction: "inventory",
          id: props.product._id,
          field: valuesInventary.field,
          value: valuesInventary.valueOfQuantity,
        },
        "updateProduct"
      ).then((resp) => {
        setValue("field", "");
        setValue("valueOfQuantity", "");

        props.loadProducts(props.currentPage);
        props.setLoadingActions((loadingActions) => ({
          ...loadingActions,
          editInventaryOfProductLoading: false,
        }));
        if (resp.success === true) {
          notification["success"]({
            message: "Inventario del producto actualizado",
          });

          resetInventary({});
        } else {
          notification["error"]({
            message: "No se pudo editar el inventario del producto",
          });
        }

        setIsModalInventaryOpen(false);
        setIsModalOpen(false);
        getQuantitysByProductId();
      });
    } else {
      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editInventaryOfProductLoading: false,
      }));
    }
  };

  const getQuantitysByProductId = () => {
    ApiTienda("products", { id: props.product._id }, "getProduct").then(
      (resp) => {
        setValue("quantityAvailable", resp.product.quantityAvailable);
        setValue("quantityStock", resp.product.quantityStock);
        setValue("totalQuantity", resp.product.totalQuantity);
      }
    );
  };



  const ShowingModalsContent = () => {
    switch (typeEventModal) {
      case "editOneProduct":
        return (
          <form onSubmit={handleSubmit(editProduct)}>
            <div className="form-campo">
              <label className="label-campo">Nombre:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputT
                  status={errors?.name?.type === "required" ? "error" : ""}
                  name="name"
                  control={control}
                  rules={{ required: true }}
                ></InputT>
                {errors?.name?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>

            <div className="form-campo">
              <label className="label-campo">Calcular precio estimado:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CheckboxT
                  name="estimatedPrice"
                  control={control}
                  onChange={(e) => {
                    setValue("salePrice", "");
                    setValue("pricePerUnity", "");
                    setValue("profitPercent", "");
                  }}
                ></CheckboxT>
              </div>
            </div>

            {watch("estimatedPrice") && (
              <>
                <div className="form-campo">
                  <label className="label-campo">Precio por unidad:</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <InputNumberT
                      min="0"
                      onChange={onChangeSalesPriceByValuePricePerUnit}
                      name="pricePerUnity"
                      status={
                        errors?.pricePerUnity?.type === "required"
                          ? "error"
                          : ""
                      }
                      control={control}
                      rules={{
                        required: watch("estimatedPrice") ? true : false,
                      }}
                    ></InputNumberT>
                    {errors?.pricePerUnity?.type === "required" && (
                      <span style={{ color: "red" }}>Campo requerido</span>
                    )}
                  </div>
                </div>
                <div className="form-campo">
                  <label className="label-campo">Porcentaje de ganancia:</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <InputNumberT
                      min="0"
                      max="100"
                      status={
                        errors?.profitPercent?.type === "required"
                          ? "error"
                          : ""
                      }
                      onChange={onChangeSalesPriceByProfitPercent}
                      name="profitPercent"
                      control={control}
                      rules={{
                        required: watch("estimatedPrice") ? true : false,
                      }}
                    ></InputNumberT>
                    {errors?.profitPercent?.type === "required" && (
                      <span style={{ color: "red" }}>Campo requerido</span>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="form-campo">
              <label className="label-campo">Precio de venta:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputNumberT
                  min="0"
                  status={errors?.salePrice?.type === "required" ? "error" : ""}
                  disabled={watch("estimatedPrice") ? true : false}
                  name="salePrice"
                  control={control}
                  rules={{ required: true }}
                ></InputNumberT>
                {errors?.salePrice?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>

            <div className="form-campo">
              <label className="label-campo">Descripción:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextAreaT
                  name="description"
                  status={
                    errors?.description?.type === "required" ? "error" : ""
                  }
                  control={control}
                  rules={{ required: true }}
                ></TextAreaT>
                {errors?.description?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>

            <div className="form-campo" style={{ width: "100%" }}>
              <label className="label-campo">Categoría:</label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20%",
                }}
              >
                <SelectT
                  placeholder="Seleccione las  categorías del producto"
                  mode="multiple"
                  status={errors?.category?.type === "required" ? "error" : ""}
                  name="category"
                  control={control}
                  rules={{ required: true }}
                >
                  {props.categoryList?.map((categoryValue, index) => {
                    return (
                      <OptionT key={index} value={categoryValue.nameCategory}>
                        {categoryValue.nameCategory}
                      </OptionT>
                    );
                  })}
                </SelectT>
                {errors?.category?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
            <div className="form-campo">
              <label className="label-campo">Imagen:</label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputT
                  placeholder="URL de la imagen"
                  rules={{
                    required: true,

                    pattern:
                      /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
                  }}
                  status={
                    errors?.image?.type === "required" ||
                    errors?.image?.type === "pattern"
                      ? "error"
                      : ""
                  }
                  name="image"
                  control={control}
                ></InputT>
                {errors?.image?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
                {errors?.image?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    La url que se ingresa no contiene una imagen
                  </span>
                )}
              </div>
            </div>

            <div className="form-campo">
              <label className="label-campo">Estado:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectT
                  placeholder="Seleccione el estado del producto"
                  name="status"
                  status={errors?.status?.type === "required" ? "error" : ""}
                  control={control}
                  rules={{ required: true }}
                >
                  <OptionT value={"DISPONIBLE"}>DISPONIBLE</OptionT>
                  <OptionT value={"AGOTADO"}>AGOTADO</OptionT>
                </SelectT>
                {errors?.status?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
            <div className="form-campo">
              <label className="label-campo">Fecha de vencimiento:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DatePickerT2
                  control={control}
                  status={
                    errors?.expirationDate?.type === "required" ||
                    errors?.expirationDate?.type === "checkDate"
                      ? "error"
                      : ""
                  }
                  rules={{
                    required: true,
                    validate: {
                      checkDate: (fecha) => {
                        return moment().isSameOrBefore(moment(fecha));
                      },
                    },
                  }}
                  name="expirationDate"
                ></DatePickerT2>
                {errors?.expirationDate?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
                {errors?.expirationDate?.type === "checkDate" && (
                  <span style={{ color: "red" }}>Fecha no valida</span>
                )}
              </div>
            </div>
            <div className="form-campo">
              <label className="label-campo">Palabras claves:</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                }}
              >
                <SelectT
                  mode={"tags"}
                  control={control}
                  status={
                    errors?.keywokeyWordsrds?.type === "required" ? "error" : ""
                  }
                  rules={{ required: true }}
                  name="keyWords"
                ></SelectT>
                {errors?.keyWords?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ margin: "20px" }}>
                <Button
                  htmlType="submit"
                  loading={props.loadingActions.editProductLoading}
                >
                  Guardar cambios
                </Button>
              </div>
              <div style={{ margin: "20px" }}>
                <Button
                  htmlType="button"
                  type="success"
                  onClick={() => {
                    setIsModalInventaryOpen(true);
                    reset();
                  }}
                >
                  Gestionar inventario
                </Button>
              </div>
            </div>
          </form>
        );

      case "showDiscountsByProductos":
        return (
          <DiscountsByProductList
            product={props.product}
            categoryList={props.categoryList}
            rangeList={props.rangeList}
            loadingActions={props.loadingActions}
            setLoadingActions={props.setLoadingActions}
          ></DiscountsByProductList>
        );

      default:
        break;
    }
  };
  return (
    <>
      <div
        style={{
          width: "350px",
          backgroundColor: "#ffff",

          margin: "10px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          border: "1px solid #D8D8D8",
        }}
      >
        <div>
          <img
            alt="example"
            src={props.product.image}
            width="350px"
            height="350px"
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <p
              style={{
                color: "#484848",
                textAlign: "center",
                fontWeight: "900",
                fontSize: "22",
                textTransform: "uppercase",
              }}
            >
              {" "}
              {props.product.name}
            </p>
            <p
              style={{
                color: "#484848",
                fontWeight: "bold",
              }}
            >
              Precio: {props.product.salePrice}
            </p>
            <p style={{ margin: 0, color: "#484848", fontWeight: "bold" }}>
              {" "}
              Categorias:
            </p>
            <div style={{ margin: "10px 0" }}>
              {props.product.category
                .toString()
                .split(",")
                .map((categoryValue) => {
                  return <Tag>{categoryValue} </Tag>;
                })}
            </div>
            <p
              style={{
                color: "#484848",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              Estado:
            </p>
            <Tag
              color={
                props.product.status === "DISPONIBLE" ? "#87d068" : "#cd201f"
              }
            >
              {props.product.status}
            </Tag>
            {props.product.expirated ? <Tag color="#cd201f">VENCIDO</Tag> : ""}
            <br />
            <p
              style={{
                margin: 3,
              }}
            >
              {" "}
              <span
                style={{
                  color: "#484848",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Cantidad en bodega:
              </span>{" "}
              {props.product.quantityStock}{" "}
            </p>{" "}
            <p
              style={{
                margin: 3,
              }}
            >
              {" "}
              <span
                style={{
                  color: "#484848",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Cantidad en venta:{" "}
              </span>{" "}
              {props.product.quantityAvailable}{" "}
            </p>{" "}
            <p></p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px 30px 5px 30px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setTypeEventModal("editOneProduct");
                showModal(props.product);
              }}
            >
              <EditOutlined />
            </Button>

            <Button
              type="danger"
              onClick={() => {
                deleteProduct(props.product._id);
              }}
            >
              {" "}
              <DeleteOutlined />
            </Button>

            <Button
              type="primary"
              onClick={() => {
                setTypeEventModal("showDiscountsByProductos");
                showModal();
              }}
            >
              Descuentos
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title={
          typeEventModal === "editOneProduct"
            ? "Editar información del producto"
            : typeEventModal === "showDiscountsByProductos"
            ? "Lista de descuentos del producto"
            : ""
        }
        transitionName=""
        open={isModalOpen}
        footer={null}
        width={1000}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        {ShowingModalsContent()}
      </Modal>

      <Modal
        title="Inventario del producto"
        open={isModalInventaryOpen}
        footer={null}
        onCancel={() => {
          setIsModalInventaryOpen(false);
        }}
      >
        <form onSubmit={handleSubmitInventary(editInventaryOfProduct)}>
          <div
            className="form-campo"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="label-campo">Campo que desea gestionar:</label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SelectT
                placeholder="Seleccione el campo que desee gestionar"
                name="field"
                status={
                  errorsInventary?.field?.type === "required" ? "error" : ""
                }
                control={controlInventary}
                rules={{ required: true }}
              >
                <OptionT value="quantityStock">Bodega</OptionT>
                <OptionT value="quantityAvailable">En Venta</OptionT>
              </SelectT>
              {errorsInventary?.field?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div
            className="form-campo"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="label-campo">
              Valor que desea sumar o restar:
            </label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <InputNumberT
                name="valueOfQuantity"
                status={
                  errorsInventary?.valueOfQuantity?.type === "required" ||
                  errorsInventary?.valueOfQuantity?.type === "min" ||
                  errorsInventary?.valueOfQuantity?.type === "max"
                    ? "error"
                    : ""
                }
                rules={{
                  required: true,
                  min:
                    watchInventary("field") === "quantityStock"
                      ? getValues("quantityStock") * -1
                      : watchInventary("field") === "quantityAvailable"
                      ? getValues("quantityAvailable") * -1
                      : "",
                }}
                control={controlInventary}
              ></InputNumberT>
              {errorsInventary?.valueOfQuantity?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errorsInventary?.valueOfQuantity?.type === "min" && (
                <span style={{ color: "red" }}>Valor no permitido</span>
              )}
            </div>
            <label className="label-campo" style={{ marginTop: "15px" }}>
              Cantidad en venta: {getValues("quantityAvailable")}
            </label>
            <label className="label-campo" style={{ marginTop: "15px" }}>
              Cantidad en bodega: {getValues("quantityStock")}
            </label>
            <label className="label-campo" style={{ marginTop: "15px" }}>
              Cantidad en total: {getValues("totalQuantity")}
            </label>
          </div>
          <Button
            htmlType="submit"
            loading={props.loadingActions.editInventaryOfProductLoading}
          >
            Guardar inventario
          </Button>
        </form>
      </Modal>
    </>
  );
}

export default ProductItem;

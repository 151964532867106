import {
  DeleteOutlined,
  EditOutlined,
  FrownFilled,
  PlusCircleOutlined,
  PlusOutlined,
  ShopFilled,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";

import axios from "axios";
import { values } from "mobx";

import { observer } from "mobx-react";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import {
  DatePickerT,
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../components/FormularioTeclas";

import { setNumero, setProductos, store } from "../../store/store";
import moment from "moment";
import ProductItem from "./ProductItem";

function ProductosList() {
  const [categoryList, setCategoryList] = useState([]);

  const [rangeList, setRangeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [inputSearcherProduct, setInputSearcherProduct] = useState("");
  const [loading, setLoading] = useState(false);

  const [showNotResultsMessage, setShowNotResultsMessage] = useState(false);
  const [showInitialMessageProducts, setShowInitialMessageProducts] =
    useState(false);

  const [loadingActions, setLoadingActions] = useState({
    createAllDiscountProductsLoading: false,
    editProductLoading: false,
    editInventaryOfProductLoading: false,
    editDiscountProductLoading: false,
    createDiscountProductLoading: false,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const showModal = (dataProduct) => {
    reset();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const loadAllProducts = (currentPageProducts) => {
    ApiTienda(
      "products",
      {
        page: currentPageProducts === undefined ? 1 : currentPageProducts,
      },
      "getProducts"
    ).then((resp) => {
      if (resp.success === true) {
        if (resp.products.length === 0) {
          setProductos(resp.products);
          setShowInitialMessageProducts(true);
          setShowNotResultsMessage(false);
        } else {
          setShowInitialMessageProducts(false);
          setShowNotResultsMessage(false);
          setProductos(resp.products);
          setShowMore(resp.showMore);
        }
      } else {
        setProductos([]);
      }

      setLoading(false);
    });
  };
  const loadProducts = async (currentPageProducts) => {
    setLoading(true);
    inputSearcherProduct.length === 0
      ? loadAllProducts(currentPageProducts)
      : loadProductsFiltered(inputSearcherProduct, currentPageProducts);
  };

  const loadCategories = () => {
    ApiTienda(
      "admin",
      { newAction: "mostrarCategoriasProductos" },
      "categories"
    ).then((resp) => {
      setCategoryList(resp.categorias);
    });
  };

  const loadRanges = () => {
    ApiTienda("admin", {}, "getRanges").then((resp) => {
      setRangeList(resp.ranges);
    });
  };

  const addAllProductsDiscounts = (discountValues) => {
    setLoadingActions((loadingActions) => ({
      ...loadingActions,
      createAllDiscountProductsLoading: true,
    }));

    ApiTienda(
      "products",
      {
        team: discountValues.team,
        range: discountValues.range,
        category: discountValues.category,
        percent: discountValues.percent,
        expirationDate: moment(discountValues.expirationDate).format(
          "YYYY-MM-DD"
        ),
      },
      "addGlobalDiscount"
    ).then((resp) => {
      reset();
      setIsModalOpen(false);
      if (resp.success === true) {
        notification["success"]({
          message: "Descuento agregado a todos los productos",
        });
      } else {
        notification["error"]({
          message: "No se pudo agregar el descuento",
        });
      }
      setLoadingActions((loadingActions) => ({
        ...loadingActions,
        createAllDiscountProductsLoading: false,
      }));
    });
  };
  const onSearchProduct = (value) => {
    if (value.length > 0) {
      setCurrentPage(1);
      loadProductsFiltered(value);
    } else if (value.length === 0) {
      setCurrentPage(1);
      loadProducts();
    }
  };

  const loadProductsFiltered = (value, currentPageUsersFiltered) => {
    setLoading(true);
    ApiTienda(
      "products",
      {
        search: value,
        page:
          currentPageUsersFiltered === undefined ? 1 : currentPageUsersFiltered,
      },
      "productSearchEngine"
    ).then((resp) => {
      if (resp.products !== undefined && resp.products.length > 0) {
        setShowNotResultsMessage(false);
        setProductos(resp.products);
        setShowMore(resp.showMore);
      } else {
        setProductos([]);
        setShowInitialMessageProducts(false);
        setShowNotResultsMessage(true);
        setShowMore(resp.showMore);
      }

      setLoading(false);
    });
  };

  const showMoreProducts = () => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    loadProducts(nextPage);
  };

  const handleSearchInputProduct = (event) => {
    if (event.target.value.length === 0) {
      loadProducts();
    }
    setInputSearcherProduct(event.target.value);
  };

  useEffect(() => {
    loadProducts();
    loadCategories();
    loadRanges();
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          margin: "0px 20px  5px 10px",
          width: "100%",
          display: "flex",
          // backgroundColor: "blue",
        }}
      >
        <div
          style={{
            margin: "10px 10px 20px 10px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            padding: "15px 15px 15px 15px",
            borderRadius: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0 15px 0 0" }}>Buscar un producto:</p>
            <Input.Search
              style={{ width: "300px" }}
              placeholder="Buscar productos"
              onSearch={onSearchProduct}
              value={inputSearcherProduct}
              onChange={handleSearchInputProduct}
              allowClear
            />
          </div>
          <Button
            style={{
              borderRadius: "25px",
            }}
            type="primary"
            onClick={() => {
              showModal();
            }}
          >
            Agregar descuento
          </Button>
        </div>
      </div>

      <h1>Productos</h1>

      <div style={{ marginRight: "20px", width: "70%" }}>
        <Spin spinning={loading} size="large">
          <Row style={{ justifyContent: "center" }}>
            {store.products.map((product) => {
              return (
                <ProductItem
                  key={product._id}
                  product={product}
                  loadProducts={loadProducts}
                  showModal={showModal}
                  rangeList={rangeList}
                  categoryList={categoryList}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  loading={loading}
                  loadingActions={loadingActions}
                  setLoadingActions={setLoadingActions}
                ></ProductItem>
              );
            })}
          </Row>
        </Spin>
        {showNotResultsMessage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <FrownFilled
                style={{
                  fontSize: "30px",
                }}
              />
            </div>

            <p
              style={{
                color: "grey",
              }}
            >
              No hay resultados
            </p>
          </div>
        )}

        {showInitialMessageProducts && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <ShopFilled
                style={{
                  fontSize: "30px",
                }}
              />
            </div>

            <p
              style={{
                color: "grey",
              }}
            >
              No se han agregado productos
            </p>
          </div>
        )}
        {!showNotResultsMessage && !showInitialMessageProducts ? (
          <div style={{ margin: "20px", textAlign: "center" }}>
            <Button
              disabled={!showMore ? true : false}
              type="primary"
              onClick={() => {
                showMoreProducts();
              }}
              style={{
                borderRadius: "15px",
              }}
              loading={loading}
            >
              Mostrar más <PlusOutlined />
            </Button>
          </div>
        ) : null}
      </div>

      <Modal
        title={"Agregar descuento"}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
        width={416}
      >
        <form onSubmit={handleSubmit(addAllProductsDiscounts)}>
          <div className="form-campo">
            <label className="label-campo">Equipo:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                status={errors?.team?.type === "required" ? "error" : ""}
                mode="multiple"
                rules={{ required: true }}
                placeholder="Seleccione el equipo para aplicar el descuento"
                name="team"
                control={control}
              >
                {" "}
                <OptionT value="Todos">Todos</OptionT>
                <OptionT value="Designer">Diseño</OptionT>
                <OptionT value="Backend">Backend</OptionT>
                <OptionT value="Frontend">Frontend</OptionT>
                <OptionT value="Ventas">Ventas</OptionT>
              </SelectT>
              {errors?.team?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Jerarquía:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                status={errors?.range?.type === "required" ? "error" : ""}
                mode="multiple"
                placeholder="Selecciona las jerarquías para aplicar los descuentos"
                name="range"
                control={control}
                rules={{ required: true }}
              >
                <OptionT value="Todos">Todos</OptionT>
                {rangeList.map((range) => {
                  return (
                    <OptionT value={range.nameRange}>{range.nameRange}</OptionT>
                  );
                })}
              </SelectT>
              {errors?.range?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Categoría:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                status={errors?.category?.type === "required" ? "error" : ""}
                mode="multiple"
                placeholder="Selecciona las categorias de los productos"
                name="category"
                control={control}
                rules={{ required: true }}
              >
                <OptionT value="Todos">Todos</OptionT>
                {categoryList.map((category) => {
                  return (
                    <OptionT value={category.nameCategory}>
                      {category.nameCategory}
                    </OptionT>
                  );
                })}
              </SelectT>
              {errors?.category?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Porcentaje:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <InputNumberT
                min="0"
                max="100"
                name="percent"
                status={
                  errors?.percent?.type === "required" ||
                  errors?.percent?.type === "min"
                    ? "error"
                    : ""
                }
                control={control}
                rules={{ required: true, min: 0 }}
              ></InputNumberT>
              {errors?.percent?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errors?.percent?.type === "min" && (
                <span style={{ color: "red" }}>
                  El porcentaje no puede ser negativo
                </span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Fecha de expiración:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <DatePickerT2
                status={
                  errors?.expirationDate?.type === "required" ||
                  errors?.expirationDate?.type === "checkDate"
                    ? "error"
                    : ""
                }
                format="YYYY-MM-DD"
                name="expirationDate"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    checkDate: (fecha) => {
                      return moment().isSameOrBefore(moment(fecha));
                    },
                  },
                }}
              ></DatePickerT2>
              {errors?.expirationDate?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errors?.expirationDate?.type === "checkDate" && (
                <span style={{ color: "red" }}>Fecha no valida</span>
              )}
            </div>
          </div>
          <Button
            htmlType="submit"
            loading={loadingActions.createAllDiscountProductsLoading}
          >
            Guardar descuento
          </Button>
        </form>
      </Modal>
    </div>
  );
}

export default observer(ProductosList);

import React, { useEffect, useState } from "react";
import {
  CheckboxT,
  DatePickerT,
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
  TextAreaT,
} from "../../components/FormularioTeclas";
import { set, useForm } from "react-hook-form";
import { Button, Modal, notification, Select } from "antd";

import { ApiTienda } from "../../API/ConfigApi";
import moment from "moment";

function CrearProducto() {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  /**
   * Metodo que envia la información del producto
   * @param {*} valores
   */
  const createProduct = (valores) => {
    setLoading(true);


    if(valores.estimatedPrice== undefined){
      setValue('estimatedPrice', false);
    }

    ApiTienda(
      "products",
      {
        name: valores.name,
        unitPrice: valores.pricePerUnity,
        estimatedPrice: valores.estimatedPrice? valores.estimatedPrice : false,
        profitPercent: valores.profitPercent,
        salePrice: valores.salePrice,
        description: valores.description,
        image: valores.image,
        category: valores.category,
        quantityStock: valores.quantityStock,
        quantityAvailable: valores.quantityAvailable,
        keyWords: valores.keywords,
        status: valores.status,
        expirationDate: moment(valores.expirationDate).format("YYYY-MM-DD"),
      },
      "createProduct"
    ).then((resp) => {
 
      if (resp.success === true) {
        notification["success"]({
          message: "Producto creado",
        });
        reset();
      } else {
        notification["error"]({
          message: "No se pudo crear el producto",
        });
      }

      setLoading(false);
    });
  };

  const onChangeSalesPriceByValuePricePerUnit = async (valuePricePerUnit) => {
    if (valuePricePerUnit && typeof valuePricePerUnit === "number") {
      if (
        getValues("profitPercent") &&
        typeof getValues("profitPercent") === "number"
      ) {
        let profit = valuePricePerUnit * (getValues("profitPercent") / 100);
        let salePrice = Math.ceil((valuePricePerUnit + profit) / 50) * 50;
        setValue("salePrice", salePrice);
      }
    }
  };

  const onChangeSalesPriceByProfitPercent = async (valueProfitPercent) => {
    if (valueProfitPercent && typeof valueProfitPercent === "number") {
      if (
        getValues("pricePerUnity") &&
        typeof getValues("pricePerUnity") === "number"
      ) {
        let profit = getValues("pricePerUnity") * (valueProfitPercent / 100);
        let salePrice =
          Math.ceil((getValues("pricePerUnity") + profit) / 50) * 50;
        setValue("salePrice", salePrice);
      }
    }
  };

  const envioConErrores = (errores) => {};

  const loadCategories = () => {
    ApiTienda(
      "admin",
      { newAction: "mostrarCategoriasProductos" },
      "categories"
    )
      .then((resp) => {
        setCategoryList(resp.categorias);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadCategories();
  }, []);

  /*   useEffect(() => {
 
  }, [watch("estimatedPrice")]); */
  return (
    <div>
      <h2>Crear Producto</h2>
      <form onSubmit={handleSubmit(createProduct, envioConErrores)}>
        <div className="form-campo">
          <label className="label-campo">Nombre:</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputT
              placeholder="Nombre del producto"
              status={errors?.name?.type === "required" ? "error" : ""}
              name="name"
              control={control}
              rules={{ required: true }}
            ></InputT>
            {errors?.name?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>

        <div className="form-campo">
          <label className="label-campo">Calcular precio estimado:</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CheckboxT
              name="estimatedPrice"
              control={control}
              onChange={() => {
                setValue("salePrice", "");
                setValue("pricePerUnity", "");
                setValue("profitPercent", "");
              }}
            ></CheckboxT>
          </div>
        </div>

        {watch("estimatedPrice") && (
          <>
            <div className="form-campo">
              <label className="label-campo">Precio por unidad:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputNumberT
                  min="0"
                  onChange={onChangeSalesPriceByValuePricePerUnit}
                  name="pricePerUnity"
                  status={
                    errors?.pricePerUnity?.type === "required" ? "error" : ""
                  }
                  control={control}
                  rules={{ required: watch("estimatedPrice") ? true : false }}
                ></InputNumberT>
                {errors?.pricePerUnity?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
            <div className="form-campo">
              <label className="label-campo">Porcentaje de ganancia:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputNumberT
                  min="0"
                  max="100"
                  status={
                    errors?.profitPercent?.type === "required" ? "error" : ""
                  }
                  onChange={onChangeSalesPriceByProfitPercent}
                  name="profitPercent"
                  control={control}
                  rules={{ required: watch("estimatedPrice") ? true : false }}
                ></InputNumberT>
                {errors?.profitPercent?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
          </>
        )}
        <div className="form-campo">
          <label className="label-campo">Precio de venta:</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputNumberT
              min="0"
              status={errors?.salePrice?.type === "required" ? "error" : ""}
              name="salePrice"
              control={control}
              disabled={watch("estimatedPrice") ? true : false}
              rules={{ required: true }}
            ></InputNumberT>
            {errors?.salePrice?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>

        <div className="form-campo">
          <label className="label-campo">Descripción:</label>
          <div
            style={{ display: "flex", flexDirection: "column", width: "20%" }}
          >
            <TextAreaT
              name="description"
              status={errors?.description?.type === "required" ? "error" : ""}
              control={control}
              rules={{ required: true }}
            ></TextAreaT>
            {errors?.description?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>

        <div className="form-campo" style={{ width: "100%" }}>
          <label className="label-campo">Categoría:</label>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "20%",
            }}
          >
            <SelectT
              placeholder="Seleccione las  categorias del producto"
              mode="multiple"
              status={errors?.category?.type === "required" ? "error" : ""}
              name="category"
              control={control}
              rules={{ required: true }}
            >
              {categoryList?.map((categoryValue, index) => {
                return (
                  <OptionT key={index} value={categoryValue.nameCategory}>
                    {categoryValue.nameCategory}
                  </OptionT>
                );
              })}
            </SelectT>
            {errors?.category?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>
        <div className="form-campo">
          <label className="label-campo">Imagen:</label>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputT
              placeholder="URL de la imagen"
              rules={{
                required: true,

                pattern: /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
              }}
              status={
                errors?.image?.type === "required" ||
                errors?.image?.type === "pattern"
                  ? "error"
                  : ""
              }
              name="image"
              control={control}
            ></InputT>
            {errors?.image?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
            {errors?.image?.type === "pattern" && (
              <span style={{ color: "red" }}>
                La url que se ingresa no contiene una imagen
              </span>
            )}
          </div>
        </div>

        <div className="form-campo">
          <label className="label-campo">Cantidad en bodega:</label>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputNumberT
              min="0"
              status={errors?.quantityStock?.type === "required" ? "error" : ""}
              name="quantityStock"
              control={control}
              rules={{ required: true }}
            ></InputNumberT>
            {errors?.quantityStock?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>
        <div className="form-campo">
          <label className="label-campo">Cantidad Disponible:</label>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputNumberT
              min="0"
              status={
                errors?.quantityAvailable?.type === "required" ? "error" : ""
              }
              name="quantityAvailable"
              control={control}
              rules={{ required: true }}
            ></InputNumberT>
            {errors?.quantityAvailable?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>

        <div className="form-campo">
          <label className="label-campo">Estado:</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SelectT
              placeholder="Seleccione el estado del producto"
              name="status"
              status={errors?.status?.type === "required" ? "error" : ""}
              control={control}
              rules={{ required: true }}
            >
              <OptionT value={"DISPONIBLE"}>DISPONIBLE</OptionT>
              <OptionT value={"AGOTADO"}>AGOTADO</OptionT>
            </SelectT>
            {errors?.status?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>
        <div className="form-campo">
          <label className="label-campo">Fecha de vencimiento:</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <DatePickerT2
              control={control}
              status={
                errors?.expirationDate?.type === "required" ||errors?.expirationDate?.type === "checkDate" ? "error" : ""
              }
              rules={{
                required: true,
                validate: {
                  checkDate: (fecha) => {
                    return moment().isSameOrBefore(moment(fecha));
                  },
                },
              }}
              name="expirationDate"
            ></DatePickerT2>
            {errors?.expirationDate?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
            {errors?.expirationDate?.type === "checkDate" && (
              <span style={{ color: "red" }}>
                La fecha no puede ser menor a la actual
              </span>
            )}
          </div>
        </div>
        <div className="form-campo">
          <label className="label-campo">Palabras claves:</label>
          <div
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <SelectT
              placeholder="Ingrese las palabras claves del producto"
              mode={"tags"}
              control={control}
              status={errors?.keywords?.type === "required" ? "error" : ""}
              rules={{ required: true }}
              name="keywords"
            ></SelectT>
            {errors?.keywords?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
        </div>

        <Button htmlType="submit" type="primary" loading={loading}>
          Crear Producto
        </Button>
      </form>
    </div>
  );
}

export default CrearProducto;

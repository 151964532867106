import {
  CheckCircleOutlined,
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, notification, Select, Space, Table } from "antd";
import Search from "antd/lib/transfer/search";
import { observer } from "mobx-react-lite";

import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import {
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../components/FormularioTeclas";
import { setUsers, store } from "../../store/store";

let teams = [
  "Diseño",
  "Ventas",
  "Soporte",
  "Backend",
  "Frontend",
  "Estudiantes",
  "Otros",
  "Mantenimiento",
  "Invitados",
];

function UsuariosList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBalance, setIsModalOpenBalance] = useState(false);
  const [rangeList, setRangeList] = useState([]);
  const [typeEventBalance, setTypeEventBalance] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilteredUsers, setShowFilteredUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActions, setLoadingActions] = useState({
    editBalance: false,
    editUser: false,
  });
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    watch,
  } = useForm();

  const {
    reset: resetBalanceUserForm,
    handleSubmit: handleSubmitBalanceUserForm,
    control: controlBalanceUserForm,
    getValues: getValuesBalanceUserForm,
    formState: { errors: errorsBalanceUserForm },
    watch: watchBalanceUserForm,
  } = useForm();

  const showModal = (userValuesForm) => {
    setIsModalOpen(true);
    reset({
      name: userValuesForm.name,
      team: userValuesForm.team,
      id: userValuesForm._id,
      cellphone: userValuesForm.cellphone,
      range: userValuesForm.range,
    });
  };

  const showModalBalance = (userBalanceFormValues) => {
    setIsModalOpenBalance(true);
    resetBalanceUserForm({
      lends: userBalanceFormValues.lends,
      balance: userBalanceFormValues.balance,
      name: userBalanceFormValues.name,
      id: userBalanceFormValues._id,
      cellphone: userBalanceFormValues.cellphone,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelBalance = () => {
    setIsModalOpenBalance(false);
  };

  const deleteUser = (userValues) => {
    Modal.confirm({
      title: `¿Estás seguro que quieres desactivar el usuario ${userValues.name}?`,
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        setLoading(true);
        ApiTienda(
          "admin",
          {
            userId: userValues._id,
            cellphone: userValues.cellphone,
          },
          "desactivateUser"
        ).then((resp) => {
          loadUsers(currentPage);

          if (resp.success === true) {
            notification["success"]({
              message: `Se desactivó el usuario ${userValues.name}`,
            });
          } else {
            notification["error"]({
              message: `No se pudo desactivar el usuario ${userValues.name}`,
            });
          }
          setLoading(false);
        });
      },
    });
  };

  const activateUser = (userValues) => {
    Modal.confirm({
      title: `Estas seguro que quieres activar el usuario ${userValues.name} `,
      onOk: () => {
        ApiTienda(
          "admin",
          {
            userId: userValues._id,
            cellphone: userValues.cellphone,
          },
          "activateUser"
        ).then((resp) => {
          loadUsers(currentPage);

          if (resp.success === true) {
            notification["success"]({
              message: `Se activó el usuario ${userValues.name}`,
            });
          } else {
            notification["error"]({
              message: `No se pudo activar el usuario ${userValues.name}`,
            });
          }
        });
      },
      onCancel: () => {},
    });
  };

  const editUser = (userValues) => {
    setLoadingActions((loadingActions) => {
      return { ...loadingActions, editUser: true };
    });
    ApiTienda("admin", {
      action: "editUserText",
      cellphone: userValues.cellphone,
      fields: {
        team: userValues.team,
        range: userValues.range,
      },
      userId: userValues.id,
    }).then((resp) => {
      loadUsers(currentPage);

      if (resp.success === true) {
        notification["success"]({
          message: "Usuario actualizado",
        });
      } else {
        notification["error"]({
          message: "No se pudo editar el usuario",
        });
      }
      setLoadingActions((loadingActions) => {
        return { ...loadingActions, editUser: false };
      });
      setIsModalOpen((isModalOpen) => !isModalOpen);
    });
  };

  const editBalanceUser = (userBalanceValues) => {
    setLoadingActions((loadingActions) => {
      return { ...loadingActions, editBalance: true };
    });
    ApiTienda("admin", {
      action:
        typeEventBalance === "plusBalanceOrCredit"
          ? "addUserNumber"
          : typeEventBalance === "minusBalanceOrCredit"
          ? "subtractUserNumber"
          : "",
      userId: userBalanceValues.id,
      cellphone: userBalanceValues.cellphone,
      field: userBalanceValues.field,
      value: userBalanceValues.value,
    }).then((resp) => {
      loadUsers(currentPage);
      setIsModalOpenBalance(() => {
        return false;
      });
      if (resp.success === true) {
        notification["success"]({
          message: "Balance actualizado",
        });
      } else {
        notification["error"]({
          message: "Ocurrio un error al editar el balance del usuario",
        });
      }
      setLoadingActions((loadingActions) => {
        return { ...loadingActions, editBalance: false };
      });
    });
  };

  const getTotalUsers = () => {
    ApiTienda("admin", {}, "countAllUsers").then((resp) => {
      setTotalUsers(resp.users);
    });
  };

  const loadAllUsers = (page) => {
    ApiTienda(
      "admin",
      {
        page: page || 1,
      },
      "getAllUsers"
    ).then((resp) => {
      setUsers(resp.users);
      setLoading(false);
    });
  };

  const loadUsers = async (page) => {
    setLoading(true);

    if (!page) {
      setCurrentPage(1);
    }

    inputSearch.length === 0
      ? loadAllUsers(page)
      : loadUsersFiltered(inputSearch, page);
  };

  const onSearchUser = (value) => {
    setCurrentPage(1);
    setInputSearch(value);
    setLoading(true);
    if (value.length > 0) {
      ApiTienda(
        "admin",
        {
          search: value,
        },
        "countUserSearchEngine"
      ).then((resp) => {
        if (resp.success === true) {
          const totalUsersSearched = resp.users[0].count;

          setTotalUsers(totalUsersSearched);

          loadUsersFiltered(value);
        } else {
          setTotalUsers(0);
          setUsers([]);
        }
        setLoading(false);
      });
    } else {
      getTotalUsers();
      loadAllUsers();
    }
  };

  const loadRanges = () => {
    ApiTienda("admin", {}, "getRanges").then((resp) => {
      setRangeList(resp.ranges);
    });
  };

  const loadUsersFiltered = (value, currentPageUsersFiltered) => {
    ApiTienda(
      "admin",
      {
        search: value,
        page:
          currentPageUsersFiltered === undefined ? 1 : currentPageUsersFiltered,
      },
      "userSearchEngine"
    ).then((resp) => {
      if (resp.users?.length > 0) {
        setUsers(resp.users);
      } else {
        setUsers([]);
      }
      setLoading(false);
    });
  };

  const handleSearchInput = (event) => {
    setInputSearch(event.target.value);
    if (event.target.value.trim().length === 0) {
      loadUsers();
      getTotalUsers();
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Celular",
      dataIndex: "cellphone",
      key: "cellphone",
    },
    {
      title: "Equipo",
      dataIndex: "team",
      key: "team",
      render: (text, record) => {
        if (record.team === "Seller") {
          return "Ventas";
        } else if (record.team === "Support") {
          return "Soporte";
        } else if (record.team == "Designer") {
          return "Diseño";
        } else {
          return record.team;
        }
      },
    },
    {
      title: "Jerarquía",
      dataIndex: "range",
      key: "range",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Prestamo",
      dataIndex: "lends",
      key: "lends",
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Activo",
      dataIndex: "active",
      key: "active",
      render: (_, record) => {
        return record.active?.toString();
      },
    },

    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              showModal(record);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            onClick={() => {
              setTypeEventBalance("plusBalanceOrCredit");
              showModalBalance(record);
            }}
          >
            <PlusCircleOutlined />
          </Button>
          <Button
            onClick={() => {
              setTypeEventBalance("minusBalanceOrCredit");
              showModalBalance(record);
            }}
          >
            <MinusCircleOutlined />
          </Button>
          {record.active === "No" ? (
            <Button
              onClick={() => {
                activateUser(record);
              }}
            >
              <CheckCircleOutlined />
            </Button>
          ) : store.loggedUser._id === record._id ||
            (record.role === "admin" &&
              store.loggedUser.superAdmin === false) ||
            (record.superAdmin === "si" &&
              store.loggedUser.superAdmin === true) ? (
            <Button
              onClick={() => {
                deleteUser(record);
              }}
              disabled={true}
            >
              <StopOutlined />
            </Button>
          ) : (
            <Button
              onClick={() => {
                deleteUser(record);
              }}
            >
              <StopOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getTotalUsers();
    loadUsers();
    loadRanges();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          backgroundColor: "#fff",
          margin: "10px 20px 10px 0",
          borderRadius: "5px",
          padding: "15px 0 15px 15px",
        }}
      >
        <p style={{ margin: "0" }}>Buscar a un usuario:</p>

        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Input.Search
              placeholder="Buscar usuarios..."
              onSearch={onSearchUser}
              allowClear
            />
          </div>
        </div>
      </div>

      <div
        style={{
          marginRight: "20px",
          borderTopRightRadius: "10px",
          overflow: "hidden",
          borderTopLeftRadius: "10px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Usuarios</h2>
        <Table
          loading={loading}
          dataSource={store.users}
          columns={columns}
          pagination={{
            current: currentPage,
            showSizeChanger: false,
            pageSize: 10,
            total: totalUsers,
            onChange: (page) => {
              loadUsers(page);
              setCurrentPage(page);
            },
          }}
        />
      </div>
      <div>
        <Modal
          title={`Editar usuario  ${getValues("name")}`}
          onCancel={handleCancel}
          open={isModalOpen}
          footer={null}
        >
          <form onSubmit={handleSubmit(editUser)}>
            <div className="form-campo">
              <label className="label-campo">Equipo:</label>
              <div style={{ width: "100%" }}>
                <SelectT
                  name="team"
                  control={control}
                  /*      rules={{ required: true }} */
                >
                  <OptionT value={"Frontend"}>Frontend</OptionT>
                  <OptionT value={"Backend"}>Backend</OptionT>
                  <OptionT value={"Designer"}>Diseño</OptionT>
                  <OptionT value={"Seller"}>Ventas</OptionT>
                  <OptionT value={"Support"}>Soporte</OptionT>
                  <OptionT value={"Estudiantes"}>Estudiantes</OptionT>
                  <OptionT value={"Otros"}> Otros</OptionT>
                  <OptionT value={"Mantenimiento"}> Mantenimiento</OptionT>
                  <OptionT value={"Invitados"}> Invitados</OptionT>
                 
                </SelectT>
              </div>
            </div>

            <div className="form-campo">
              <label className="label-campo">Jerarquía:</label>
              <div style={{ width: "100%" }}>
                <SelectT
                  placeholder="Seleccione la jerarquía"
                  name="range"
                  control={control}
                  rules={{ required: true }}
                >
                  {rangeList.map((range) => {
                    return (
                      <OptionT key={range._id}value={range.nameRange}>
                        {range.nameRange}
                      </OptionT>
                    );
                  })}
                </SelectT>
              </div>
            </div>
            <Button
              htmlType="submit"
              disabled={isDirty ? false : true}
              loading={loadingActions.editUser}
            >
              Guardar
            </Button>
          </form>
        </Modal>

        <Modal
          title={
            typeEventBalance === "plusBalanceOrCredit"
              ? `Realizar crédito a ${getValuesBalanceUserForm("name")}`
              : typeEventBalance === "minusBalanceOrCredit"
              ? `Realizar débito a ${getValuesBalanceUserForm("name")}`
              : ""
          }
          onCancel={handleCancelBalance}
          open={isModalOpenBalance}
          footer={null}
          width={600}
        >
          <form onSubmit={handleSubmitBalanceUserForm(editBalanceUser)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <label style={{ fontWeight: "700" }}>
                Balance : {getValuesBalanceUserForm("balance")} pesos
              </label>
              <label style={{ marginLeft: "100px", fontWeight: "700" }}>
                Prestamo : {getValuesBalanceUserForm("lends")} pesos
              </label>
            </div>

            <div
              className="form-campo"
              style={{ width: "70%", display: "flex", flexDirection: "column" }}
            >
              <label style={{ marginTop: "20px" }} className="label-campo">
                Escoge tu opción:
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SelectT
                  placeholder="Selecciona el tipo de transacción"
                  status={
                    errorsBalanceUserForm?.field?.type === "required"
                      ? "error"
                      : ""
                  }
                  name="field"
                  control={controlBalanceUserForm}
                  rules={{ required: true }}
                >
                  <OptionT value="lends"> PRESTAMO</OptionT>
                  <OptionT value="balance"> BALANCE</OptionT>
                </SelectT>

                {errorsBalanceUserForm?.field?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
            <div
              className="form-campo"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label className="label-campo">Pon tu valor:</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputNumberT
                  name="value"
                  status={
                    errorsBalanceUserForm?.value?.type === "required" ||
                    errorsBalanceUserForm?.value?.type === "min" ||
                    errorsBalanceUserForm?.value?.type === "max"
                      ? "error"
                      : ""
                  }
                  control={controlBalanceUserForm}
                  rules={{
                    required: true,
                    min: "0",
                    max:
                      typeEventBalance === "minusBalanceOrCredit" &&
                      watchBalanceUserForm("field") === "balance"
                        ? getValuesBalanceUserForm("balance")
                        : typeEventBalance === "minusBalanceOrCredit" &&
                          watchBalanceUserForm("field") === "lends"
                        ? getValuesBalanceUserForm("lends")
                        : null,
                  }}
                ></InputNumberT>
                {errorsBalanceUserForm?.value?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
                {errorsBalanceUserForm?.value?.type === "min" && (
                  <span style={{ color: "red" }}>
                    El valor minimo no puede ser menor a 0
                  </span>
                )}
                {errorsBalanceUserForm?.value?.type === "max" && (
                  <span style={{ color: "red" }}>Valor no permitido</span>
                )}
              </div>
            </div>

            <Button htmlType="submit" loading={loadingActions.editBalance}>
              Guardar
            </Button>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default observer(UsuariosList);

import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import LoginPage from "../Pages/Auth/LoginPage";
import ContenidoTienda from "../Pages/ContenidoTienda";
import FooterAdmin from "../components/FooterAdmin";
import RutasPrivadas from "./RutasPrivadas";
import RutasPublicas from "./RutasPublicas";

import { observer } from "mobx-react";
import RecoveryPassword from "../Pages/Auth/RecoveryPassword";
import { ApiTienda } from "../API/ConfigApi";
import { setIsSuperAdmin } from "../store/store";

function Rutas() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<RutasPublicas />}>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/recovery-password"
            element={<RecoveryPassword />}
          ></Route>
        </Route>

        {/*     <Route element={<RutasPrivadas />}>
          <Route path="/tienda/*" element={<ContenidoTienda />}></Route>
        </Route> */}

        <Route
          path="/tienda/*"
          element={
            <RutasPrivadas>
              <ContenidoTienda />
            </RutasPrivadas>
          }
        />
      </Routes>
    </div>
  );
}

export default observer(Rutas);

import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";

function EditProfileAdmin() {
  const { handleSubmit, control, reset, getValues } = useForm();
  const [loading, setLoading] = useState(false);

  const editAdminInfo = ({ cellphone, name, range }) => {
    ApiTienda(
      "admin",
      {
        profile: { cellphone, name, range },
        newAction: "updateProfileAdmin",
      },
      "othersFuntionsAdmin"
    ).then((resp) => {});
  };

  const getProfileAdmin = () => {
    setLoading(true);
    ApiTienda(
      "admin",
      {
        newAction: "getProfileAdmin",
      },
      "othersFuntionsAdmin"
    ).then((resp) => {

      setLoading(false);
      reset(resp.user);
    });
  };
  useEffect(() => {
    getProfileAdmin();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: loading ? "" : "#fff",
            borderRadius: "5px",
            boxShadow: "1px 1px 10px #dbdbdb",
          }}
        >
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin size="large"></Spin>
            </div>
          ) : (
            <div
              style={{
                margin: "20px",
              }}
            >
              <h2>Perfil del administrador</h2>
              <form onSubmit={handleSubmit(editAdminInfo)}>
                <div className="form-campo">
                  <label className="label-campo">Nombre:</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {getValues("name")}
                  </div>
                </div>

                <div className="form-campo">
                  <label className="label-campo">Celular:</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {getValues("cellphone")}
                  </div>
                </div>

                <div className="form-campo">
                  <label className="label-campo">Jerarquía:</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {getValues("range")}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditProfileAdmin;

import axios from "axios";
import { action, observable } from "mobx";
import { ApiTienda } from "../API/ConfigApi";

const store = observable({
  loggedUser: {},
  users: [],
  rangesListToShow: [],
  categoriesListToShow: [],
  products: [],
  sales: [],

  token: undefined,
});

const setProductos = action((products) => {
  store.products = products;
});

const setToken = action((token) => {
  store.token = token;
});

const setUsers = action((users) => {
  store.users = users;
});
const setSales = action((sales) => {
  store.sales = sales;
});

const setLoggedUser = action((loggedUser) => {
  store.loggedUser = loggedUser;
});

const setRangesListToShow = action((rangesListToShow) => {
  store.rangesListToShow = rangesListToShow;
});

const setCategoriesListToShow = action((categoriesListToShow) => {
  store.categoriesListToShow = categoriesListToShow;
});
export {
  store,
  setProductos,
  setToken,
  setUsers,
  setLoggedUser,
  setSales,
  setRangesListToShow,
  setCategoriesListToShow,
};

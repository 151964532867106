import { useState } from "react";
import { Layout, Menu, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
function Sidebar(props) {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const { Sider } = Layout;
  return (
    <Sider

      style={{ marginRight: "20px", minHeight: "100vh" ,position:"fixed"}}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <p
        style={{
          color: "white",
          textAlign: "center",
          margin: "10px",
          fontSize: "20px",
        }}
      >
        <img
          src="https://ucarecdn.com/66adf019-181d-4552-9816-c964f6b5de24/photo_20221105141801.jpeg"
          alt="logo"
          style={{ width: collapsed?"50px":"100px"}}
        />
      </p>
      <Menu
        theme="light"
        items={props.items}
        onClick={props.onClick}
        mode="inline"
      />
    </Sider>
  );
}

export default Sidebar;

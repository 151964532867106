import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;
function FooterAdmin() {
  return (
    <Footer>
      <p style={{textAlign:"center",color:"black"}}> Copyright 2022 TeclasDev. Todos los derechos reservados</p>
    </Footer>
  );
}

export default FooterAdmin;

import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";
import CategoryList from "./CategoryList";
import ListarCategorias from "./CategoryList";

function CreateCategory() {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [loadingActions, setLoadingActions] = useState({
    createCategoryLoading: false,

    editCategoryLoading: false,
  });

  const [categoryList, setCategoryList] = useState([]);

  const createCategory = (values) => {
    setLoadingActions((loadingActions) => ({
      ...loadingActions,
      createCategoryLoading: true,
    }));

    ApiTienda(
      "admin",
      {
        category: values.valueCategory,
        newAction: "createCategory",
      },
      "categories"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Categoría creada",
        });
      } else {
        notification["error"]({
          message: "No se pudo crear la categoría",
        });
      }
      setLoadingActions((loadingActions) => ({
        ...loadingActions,
        createCategoryLoading: false,
      }));
      loadCategories();
      reset();
    });
  };

  const loadCategories = () => {
    setLoading(true);
    ApiTienda(
      "admin",
      { newAction: "mostrarCategoriasProductos" },
      "categories"
    )
      .then((resp) => {
        setCategoryList(resp.categorias);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          margin: "10px 20px 10px 0px",
          padding: "10px 0 10px 0",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Crear categoría</h2>
        <form
          onSubmit={handleSubmit(createCategory)}
          style={{ marginBottom: "15px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ margin: "0 15px 0 0" }}>Nombre:</p>
              <InputT
                ancho="300px"
                placeholder="Ingrese el nombre de la categoría"
                name="valueCategory"
                status={
                  errors?.valueCategory?.type === "required" ? "error" : ""
                }
                control={control}
                rules={{ required: true }}
              ></InputT>
              <Button
                htmlType="submit"
                loading={loadingActions.createCategoryLoading}
                style={{ marginLeft: "20px" }}
              >
                Guardar
              </Button>
            </div>
            {/* {errors?.valueCategory?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )} */}
          </div>
        </form>
        {errors?.valueCategory?.type === "required" && (
          <p style={{ color: "red", margin: "0", textAlign: "center" }}>
            Campo requerido
          </p>
        )}
      </div>

      <div>
        <h2 style={{ textAlign: "center" }}>Lista de categorías</h2>
        <CategoryList
          loading={loading}
          setLoading={setLoading}
          loadingActions={loadingActions}
          setLoadingActions={setLoadingActions}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
          loadCategories={loadCategories}
        />
      </div>
    </div>
  );
}

export default CreateCategory;

import { Button, notification, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";
import AvatarsList from "./AvatarsList";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
function CreateAvatar() {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingActions, setLoadingActions] = useState({
    createAvatarLoading: false,
    editAvatarLoading: false,
  });

  const [avatarList, setAvatarList] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const createAvatar = (values) => {
    setLoadingActions((loadingActions) => ({
      ...loadingActions,
      createAvatarLoading: true,
    }));
    ApiTienda(
      "admin",
      {
        name: values.avatarName,
        link: values.link,
      },
      "addAvatar"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Avatar creado",
        });
      } else {
        notification["error"]({
          message: "No se pudo crear el avatar",
        });
      }

      setLoadingActions((loadingAction) => ({
        ...loadingActions,
        createAvatarLoading: false,
      }));
      reset();
      loadAvatars(currentPage, true);
    });
  };

  const loadAvatars = (currentPageAvatar, todosValue) => {
    setLoading(true);
    ApiTienda(
      "admin",
      {
        page: currentPageAvatar === undefined ? 1 : currentPageAvatar,
        todos: todosValue === undefined || todosValue === false ? false : true,
      },
      "getAvatars"
    ).then((resp) => {
      if (todosValue === false || todosValue === undefined) {
        const newArrayOfAvatars = [...avatarList, ...resp.avatars];
        setAvatarList(newArrayOfAvatars);
      } else {
        setAvatarList(resp.avatars);
      }

      setShowMore(resp.showMore);
      setLoading(false);
    });
  };

  const showMoreAvatars = () => {
    let nextPage = currentPage + 1;

    setCurrentPage(nextPage);
    loadAvatars(nextPage, false);
  };

  useEffect(() => {
    loadAvatars();
  }, []);
  return (
    <div>
      <div
        style={{
          borderRadius: "5px",
          margin: "10px 10px 0 0",
          padding: "10px 0 10px 0",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Crear avatar</h2>
        <form
          onSubmit={handleSubmit(createAvatar)}
          style={{ marginBottom: "30px" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",

                width: "750px",
              }}
            >
              <div
                style={{
                  width: "600px",

                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: "0 15px 0 0" }}>Nombre:</p>
                  <InputT
                    placeholder="Nombre del avatar"
                    name="avatarName"
                    status={
                      errors?.avatarName?.type === "required" ? "error" : ""
                    }
                    control={control}
                    rules={{ required: true }}
                  ></InputT>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: "0 15px 0 0", whiteSpace: "nowrap" }}>
                    Link de la imagen:
                  </p>
                  <InputT
                    placeholder="URL de la imagen del avatar"
                    name="link"
                    status={
                      errors?.link?.type === "required" ||
                      errors?.link?.type === "pattern"
                        ? "error"
                        : ""
                    }
                    control={control}
                    rules={{
                      required: true,
                      pattern:
                        /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
                    }}
                  ></InputT>
                </div>
              </div>
              <Button
                htmlType="submit"
                loading={loadingActions.createAvatarLoading}
              >
                Crear Avatar
              </Button>
            </div>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          {errors?.avatarName?.type === "required" && (
            <span style={{ color: "red" }}>
              Se requiere el nombre del avatar
            </span>
          )}
          {errors?.link?.type === "required" && (
            <span style={{ color: "red", textAlign: "center" }}>
              Se requiere el link de del avatar
            </span>
          )}
          {errors?.link?.type === "pattern" && (
            <span style={{ color: "red" }}>
              El link suministrado no contiene una imagen
            </span>
          )}
        </div>
      </div>

      <Spin spinning={loading} >
        <h2 style={{ textAlign: "center" }}>Lista de avatars</h2>

        <AvatarsList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showMore={showMore}
          setShowMore={setShowMore}
          loadAvatars={loadAvatars}
          avatarList={avatarList}
          loading={loading}
          setLoading={setLoading}
          loadingActions={loadingActions}
          setLoadingActions={setLoadingActions}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ margin: "10px" }}>
            <Button
              style={{
                borderRadius: "25px",
              }}
              type="primary"
              disabled={!showMore ? true : false}
              onClick={showMoreAvatars}
              loading={loading}
            >
              Mostrar más <PlusOutlined />
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default CreateAvatar;

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Space, Table } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";

function RangesList(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteRange = (rangeValue) => {
    Modal.confirm({
      title: "¿Quieres eliminar la jerarquía?",
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        props.setLoading();
        ApiTienda(
          "admin",
          {
            id: rangeValue._id,
            nameRange: rangeValue.nameRange,
          },
          "deleteRange"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Jerarquía eliminada",
            });
          } else {
            notification["error"]({
              message: "No se pudo eliminar el jerarquía",
            });
          }
          props.loadRanges();
        });
      },
    });
  };

  const editRange = (rangeValue) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editRangeLoading: true,
    }));
    ApiTienda(
      "admin",
      {
        id: rangeValue.id,
        oldName: rangeValue.oldname,
        newName: rangeValue.nameRange,
      },
      "updateRange"
    ).then((resp) => {
      reset();
      if (resp.success === true) {
        notification["success"]({
          message: "Jerarquía actualizada",
        });
      } else {
        notification["error"]({
          message: "No se pudo editar la jerarquía",
        });
      }
      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editRangeLoading: false,
      }));
      setIsModalOpen(false);
      props.loadRanges();
    });
  };

  const showModal = (rangeValuesForm) => {
    setIsModalOpen(true);
    reset({
      id: rangeValuesForm._id,
      oldname: rangeValuesForm.nameRange,
      nameRange: rangeValuesForm.nameRange,
    });
  };

  /*  const handleCancel = () => {
      setIsModalOpen(false);
    };
  
  
   */
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nameRange",
      key: "nameRange",
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteRange(record);
            }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            onClick={() => {
              showModal(record);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          marginRight: "20px",
          borderTopRightRadius: "10px",
          overflow: "hidden",
          borderTopLeftRadius: "10px",
        }}
      >
        <Table
          dataSource={props.rangeList}
          columns={columns}
          loading={props.loading}
        />
      </div>
      <Modal
        title="Editar jeraquía"
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <form onSubmit={handleSubmit(editRange)}>
          <div className="form-campo">
            <label className="label-campo">Nombre:</label>
            <InputT
              name="nameRange"
              control={control}
              rules={{ required: true }}
            ></InputT>
          </div>

          <Button
            htmlType="submit"
            loading={props.loadingActions.editRangeLoading}
          >
            Guardar
          </Button>
        </form>
      </Modal>
    </div>
  );
}

export default RangesList;

import './App.css';
import {Button,Layout} from 'antd'

import Rutas from './Rutas/Rutas';
import { observer } from 'mobx-react';


function App() {
  return (

       <Rutas/>

  
  );
}

export default observer(App);

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";

function CategoryList(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const deleteCategory = (categoryValue) => {
    Modal.confirm({
      title: "¿Quieres eliminar la categoría?",
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        props.setLoading(true);
        ApiTienda(
          "admin",
          {
            id: categoryValue._id,
            nameCategory: categoryValue.nameCategory,
            newAction: "deleteCategory",
          },
          "categories"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Categoría eliminada",
            });
          } else {
            notification["error"]({
              message: "No se pudo eliminar la categoría",
            });
          }

          props.loadCategories();
          props.setLoading(false);
        });
      },
    });
  };

  const editCategory = (categoryValues) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editCategoryLoading: true,
    }));

    ApiTienda(
      "admin",
      {
        id: categoryValues.id,
        odlname: categoryValues.odlname,
        newname: categoryValues.nameCategory,

        newAction: "updateCategories",
      },
      "categories"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Categoría actualizada",
        });
      } else {
        notification["error"]({
          message: "No se pudo actualizar la categoría",
        });
      }
      reset();

      setIsModalOpen(false);

      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editCategoryLoading: false,
      }));
      props.loadCategories();
    });
  };

  const showModal = (categoryValuesForm) => {
    setIsModalOpen(true);
    reset({
      id: categoryValuesForm._id,
      odlname: categoryValuesForm.nameCategory,
      nameCategory: categoryValuesForm.nameCategory,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nameCategory",
      key: "nameCategory",
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteCategory(record);
            }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            onClick={() => {
              showModal(record);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title="Editar categoría"
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <form onSubmit={handleSubmit(editCategory)}>
          <div className="form-campo">
            <label className="label-campo">Categoría:</label>
            <InputT
              placeholder="Nombre de la categoría"
              name="nameCategory"
              control={control}
              rules={{ required: true }}
            ></InputT>
          </div>

          <Button
            htmlType="submit"
            loading={props.loadingActions.editCategoryLoading}
          >
            Guardar
          </Button>
        </form>
      </Modal>
      <div
        style={{
          marginRight: "20px",
          borderTopRightRadius: "10px",
          overflow: "hidden",
          borderTopLeftRadius: "10px",
        }}
      >
     
        <Table
          loading={props.loading}
          dataSource={props.categoryList}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default CategoryList;

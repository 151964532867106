import axios from "axios";



const CONFIG = {
  url: process.env.REACT_APP_API_URl,
};

export const instanceProducts = axios.create({
  baseURL: CONFIG.url,
});
export const ApiTienda = async function (collection, data, action) {
  if (action) {
    data.action = action;
  }

  return new Promise((resp, err) => {
    instanceProducts
      .post(collection, data)
      .then(function (response) {
        resp(response.data);
      })
      .catch(function (error) {
        console.log(error);
        err(error);
      });
  });
};

instanceProducts.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
  
    if (error.response?.status === 401) {
     
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

instanceProducts.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  };

  return config;
});

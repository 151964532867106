import { Button, notification, Spin } from "antd";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiTienda } from "../../API/ConfigApi";
import FooterAdmin from "../../components/FooterAdmin";
import { InputNumberT, InputT } from "../../components/FormularioTeclas";
import SendCode from "./SendCode";
import SendNumber from "./SendNumber";
import SendPassword from "./SendPassword";

function RecoveryPassword() {
  const methods = useForm();
  const navigate = useNavigate();
  const [loadingAction, setLoadingAction] = useState({
    loadingNumber: false,
    loadingPassword: false,
    loadingCode: false,
  });

  const [typeOfEvent, setTypeOfEvent] = useState("sendingNumber");

  const recoveryPasswordUser = (valuesFormGlobal) => {
    setLoadingAction((loadingAction) => ({
      ...loadingAction,
      loadingNumber: true,
    }));
    ApiTienda(
      "passwordRecovery",
      {
        cellphone: String(valuesFormGlobal.cellphone),
      },
      "passwordRecovery"
    ).then((resp) => {
    
      if (resp.success === true) {

        notification["success"]({
          message:"Se envio el codigo de recuperacion al número de telefono"
        })
        setTypeOfEvent("sendingCode");

        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingNumber: false,
        }));
      } else {
     
        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingNumber: false,
        }));

        notification["error"]({
          message: "Hubo un error y no se pudo enviar el número del telefono",
        });
      }
    });
  };

  const sendCodeNumber = (valuesFormGlobal) => {
    setLoadingAction((loadingAction) => ({
      ...loadingAction,
      loadingCode: true,
    }));
    ApiTienda(
      "passwordRecovery",
      {
        cellphone: String(valuesFormGlobal.cellphone),
        code: String(valuesFormGlobal.cellphoneCode),
      },
      "verifyCode"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Procede a cambiar la contraseña",
        });
        setTypeOfEvent("sendingPassword");
        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingCode: false,
        }));
      } else {
        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingCode: false,
        }));
        notification["error"]({
          message:
            " Hubo un error y no se pudo procesar el código de confirmación del número",
        });
      }
    });
  };

  const sendNewPassword = (valuesFormGlobal) => {
    setLoadingAction((loadingAction) => ({
      ...loadingAction,
      loadingPassword: true,
    }));

    ApiTienda(
      "passwordRecovery",
      {
        cellphone: String(valuesFormGlobal.cellphone),
        code: String(valuesFormGlobal.cellphoneCode),
        password: valuesFormGlobal.passwordCellphone,
      },
      "updatePassword"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Se cambio exitosamente la contraseña",
        });
        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingPassword: false,
        }));

        navigate("/login");
      } else {
        notification["error"]({
          message: "Hubo un error y no se pudo recuperar la contraseña",
        });
        setLoadingAction((loadingAction) => ({
          ...loadingAction,
          loadingPassword: false,
        }));
      }
    });
  };

  return (
    <FormProvider {...methods}>
      {typeOfEvent === "sendingNumber" ? (
        <SendNumber
          setTypeOfEvent={setTypeOfEvent}
          recoveryPasswordUser={recoveryPasswordUser}
          loadingAction={loadingAction}
        />
      ) : typeOfEvent === "sendingCode" ? (
        <SendCode
          setTypeOfEvent={setTypeOfEvent}
          recoveryPasswordUser={recoveryPasswordUser}
          sendCodeNumber={sendCodeNumber}
          loadingAction={loadingAction}
        />
      ) : typeOfEvent === "sendingPassword" ? (
        <SendPassword
          sendNewPassword={sendNewPassword}
          loadingAction={loadingAction}
        />
      ) : (
        ""
      )}
    </FormProvider>
  );
}

export default RecoveryPassword;

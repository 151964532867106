import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import UsuariosList from "./Usuarios/UsuariosList";
import ProductosList from "./Productos/ProductosList";
import AdminList from "./Admin/AdminList";
import CrearProducto from "./Productos/CrearProducto";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import {
  TeamOutlined,
  PoweroffOutlined,
  UserOutlined,
  SmileOutlined,
  DollarCircleOutlined,
  CrownOutlined,
  TagsOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { setLoggedUser, setToken, store } from "../store/store";
import EditProfileAdmin from "./Admin/EditProfileAdmin";

import CreateAvatar from "./Avatars/CreateAvatar";
import CreateRange from "./Rangos/CreateRange";
import CreateCategory from "./Categorias/CreateCategory";
import SalesList from "./Ventas/SalesList";
import { ApiTienda } from "../API/ConfigApi";

function ContenidoTienda() {
  const { Header, Content, Footer, Sider } = Layout;

  const navigate = useNavigate();

  const getProfileAdmin = () => {
    ApiTienda(
      "admin",
      {
        newAction: "getProfileAdmin",
      },
      "othersFuntionsAdmin"
    ).then((resp) => {
 
      if (resp.user !== undefined || resp.user !== false) {
        setLoggedUser({ ...resp.user, superAdmin: resp.superAdmin });
      } else {
        setLoggedUser({});
      }
    });
  };
  useEffect(() => {
     getProfileAdmin(); 
  }, []);
  const items = [
    {
      label: "Usuarios",
      key: "usuarios-list",
      icon: <TeamOutlined />,
    },
    {
      label: "Productos",
      key: "productos-list",
      icon: <ShopOutlined />,
      children: [
        {
          label: "Crear Producto",
          key: "crear-producto",
        },
        {
          label: "Listar Productos",
          key: "productos-list",
        },
      ],
    },
    {
      label: "Categorias",
      key: "categories-admin",
      icon: <TagsOutlined />,
    },
    {
      label: "Jerarquías",
      key: "rangos-admin",
      icon: <CrownOutlined />,
    },
    {
      label: "Ventas",
      key: "ventas-admin",
      icon: <DollarCircleOutlined />,
    },
    {
      label: "Avatars",
      key: "avatar-admin",
      icon: <SmileOutlined />,
    },
    {
      label: "Perfil",
      key: "edit-info",
      icon: <UserOutlined />,
    },
    {
      label: "Cerrar sesión",
      key: "cerrar-sesion",
      icon: <PoweroffOutlined />,
      danger: true,
    },
  ];

  const onClick = ({ key }) => {
    if (key === "cerrar-sesion") {
      navigate("/login");
      localStorage.clear();
      setToken(null);
    } else navigate(key);
  };
  return (
    <Layout>
      <Sidebar items={items} onClick={onClick} />

      <Content
      
      style={{
        minHeight:"100vh",
        marginLeft:"220px"
      }}>
        <Routes>
          <Route path="/usuarios-list" element={<UsuariosList />} />
          <Route path="/productos-list" element={<ProductosList />} />
          <Route path="/admin" element={<AdminList />} />
          <Route path="/crear-producto" element={<CrearProducto />} />
          <Route path="/edit-info" element={<EditProfileAdmin />} />
          <Route path="/categories-admin" element={<CreateCategory />} />
          <Route path="/rangos-admin" element={<CreateRange />} />
          <Route path="/avatar-admin" element={<CreateAvatar />} />
          <Route path="/ventas-admin" element={<SalesList />}></Route>

          <Route path="/*" element={<Navigate to="usuarios-list" />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default ContenidoTienda;

import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";

function AvatarsList(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const deleteAvatar = (avatarValue) => {
    Modal.confirm({
      title: "¿Quieres eliminar el avatar?",
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        ApiTienda(
          "admin",
          {
            id: avatarValue._id,
          },
          "deleteAvatar"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Avatar eliminado",
            });
          } else {
            notification["error"]({
              message: "No se pudo eliminar el avatar",
            });
          }
          props.loadAvatars(props.currentPage, true);
        });
      },
    });
  };

  const showModal = (avatar) => {
    setIsModalOpen(true);
    reset({
      avatarName: avatar.name,
      link: avatar.link,
      _id: avatar._id,
    });
  };
  const editAvatar = (avatarValues) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editAvatarLoading: true,
    }));

    ApiTienda(
      "admin",
      {
        id: avatarValues._id,
        update: {
          name: avatarValues.avatarName,
          link: avatarValues.link,
        },
      },
      "updateAvatar"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Edición exitosa",
        });
      } else {
        notification["error"]({
          message: "No se pudo editar el avatar",
        });
      }
      props.loadAvatars(props.currentPage, true);
      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editAvatarLoading: false,
      }));
      setIsModalOpen(false);
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {props.avatarList.map((avatar) => {
          return (
            <div style={{ margin: "20px" }}>
              <img
                style={{ borderRadius: "50%", width: "200px", height: "200px" }}
                src={avatar.link}
                alt=""
              />
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {avatar.name}
              </p>
              <div style={{ textAlign: "center" }}>
                {avatar.default === false && (
                  <Button
                    htmlType="button"
                    onClick={() => {
                      deleteAvatar(avatar);
                    }}
                  >
                    <DeleteOutlined style={{ color: "red" }} />
                  </Button>
                )}

                <Button
                  htmlType="button"
                  onClick={() => {
                    showModal(avatar);
                  }}
                >
                  <EditOutlined style={{ color: "blue" }} />
                </Button>
              </div>
            </div>
          );
        })}

        <Modal
          title="Editar información del avatar"
          open={isModalOpen}
          footer={null}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <form
            onSubmit={handleSubmit(editAvatar)}
          >
            <div className="form-campo">
              <label className="label-campo">Nombre:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputT
                  placeholder="Nombre del avatar"
                  name="avatarName"
                  status={
                    errors?.avatarName?.type === "required" ? "error" : ""
                  }
                  control={control}
                  rules={{ required: true }}
                ></InputT>
                {errors?.avatarName?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
              </div>
            </div>
            <div className="form-campo">
              <label className="label-campo">Link de la imagen:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputT
                  placeholder="URL de la imagen del avatar"
                  name="link"
                  status={
                    errors?.link?.type === "required" ||
                    errors?.link?.type === "pattern"
                      ? "error"
                      : ""
                  }
                  control={control}
                  rules={{
                    required: true,
                    pattern:
                      /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
                  }}
                ></InputT>
                {errors?.link?.type === "required" && (
                  <span style={{ color: "red" }}>Campo requerido</span>
                )}
                {errors?.link?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    El link suministrado no contiene una imagen
                  </span>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
              s
            >
              <Button
                htmlType="submit"
                loading={props.loadingActions.editAvatarLoading}
              >
                Guardar cambios
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default AvatarsList;

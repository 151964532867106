import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { getValue } from "@testing-library/user-event/dist/utils";
import { Button, Modal, notification, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import {
  DatePickerT,
  DatePickerT2,
  InputNumberT,
  OptionT,
  SelectT,
} from "../../components/FormularioTeclas";

function DiscountsByProductList(props) {
  const [eventTypeModal, setEventTypeModal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm();

  const showModal = (discountFormValues) => {
    if (!discountFormValues) {
      reset({});
    }
    reset(discountFormValues);
    setIsModalOpen(true);
  };

  const loadDiscountsByProduct = (idProduct) => {
    setLoading(true);
    ApiTienda(
      "products",
      {
        idProduct,
      },
      "getDiscounts"
    ).then((resp) => {
      setDiscountList(resp.discounts);
      setLoading(false);
    });
  };
  const createIndividualProductDiscount = (discountProductIndividualValues) => {
    /*     createAllDiscountProductsLoading: false,
    editProductLoading: false,
    editInventaryOfProductLoading:false,
    editDiscountProductLoading: false,
    createDiscountProductLoading: false, */

    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      createDiscountProductLoading: true,
    }));
    ApiTienda(
      "products",
      {
        idProduct: props.product._id,
        team: discountProductIndividualValues.team,
        range: discountProductIndividualValues.range,

        percent: discountProductIndividualValues.percent,
        expirationDate: moment(
          discountProductIndividualValues.expirationDate
        ).format("YYYY-MM-DD"),
      },
      "addOneDiscount"
    ).then((resp) => {
      if (resp.success === true) {
        loadDiscountsByProduct(props.product._id);
        notification["success"]({
          message: "Se agrego correctamente el descuento al producto",
        });
      } else {
        loadDiscountsByProduct(props.product._id);
        notification["error"]({
          message:
            "Hubo un error y no se pudo agregar el descuento al producto",
        });
      }
      reset();

      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        createDiscountProductLoading: false,
      }));
      /* setIsModalOpen(false); */
    });
  };

  const deleteDiscountByProduct = (idDiscount, idProduct) => {
    Modal.confirm({
      title: `Estas seguro que quieres desactivar el descuento   `,
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        setLoading(true);
        ApiTienda(
          "products",
          {
            idProduct,
            idDiscount,
          },
          "deleteDiscount"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Se elimino el descuento de forma exitosa",
            });
          } else {
            notification["error"]({
              message: "Hubo un error y no se pudo eliminar el descuento",
            });
          }
          loadDiscountsByProduct(idProduct);
          setLoading(false);
        });
      },
    });
  };

  const editDiscountByProduct = (valuesForm) => {
    props.setLoadingActions((loadingActions) => ({
      ...loadingActions,
      editDiscountProductLoading: true,
    }));

    ApiTienda(
      "products",
      {
        idProduct: props.product._id,
        idDiscount: valuesForm.idDiscount,
        data: {
          percent: valuesForm.percent,
          range: valuesForm.range,
          team: valuesForm.team,
          expirationDate: moment(valuesForm.expirationDate).format(
            "YYYY-MM-DD"
          ),
        },
      },
      "updateDiscount"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "El descuento se actualizo de forma exitosa",
        });
      } else {
        notification["error"]({
          message: resp.msg,
        });
      }
      loadDiscountsByProduct(props.product._id);

      props.setLoadingActions((loadingActions) => ({
        ...loadingActions,
        editDiscountProductLoading: false,
      }));
      setIsModalOpen(false);
    });
  };

  const ShowingModalContentDiscount = () => {
    if (eventTypeModal === "editDiscountProduct") {
      return (
        <form onSubmit={handleSubmit(editDiscountByProduct)}>
          <div className="form-campo">
            <label className="label-campo">Equipo:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                rules={{ required: true }}
                mode="multiple"
                placeholder="Seleccione el equipo para aplicar el descuento"
                name="team"
                status={errors?.team?.type === "required" ? "error" : ""}
                control={control}
              >
                <OptionT value={"Frontend"}>Frontend</OptionT>
                <OptionT value={"Backend"}>Backend</OptionT>
                <OptionT value={"Designer"}>Diseño</OptionT>
                <OptionT value={"Seller"}>Ventas</OptionT>
                <OptionT value={"Support"}>Soporte</OptionT>
                <OptionT value={"Estudiantes"}>Estudiantes</OptionT>
                <OptionT value={"Mantenimiento"}> Mantenimiento</OptionT>
                <OptionT value={"Invitados"}> Invitados</OptionT>
                <OptionT value={"Otros"}> Otros</OptionT>
              </SelectT>
              {errors?.team?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Rango:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                rules={{ required: true }}
                mode="multiple"
                status={errors?.range?.type === "required" ? "error" : null}
                placeholder="Selecciona las jerarquías para aplicar los descuentos"
                name="range"
                control={control}
              >
                <OptionT value="Todos">Todos</OptionT>
                {props.rangeList.map((range) => {
                  return (
                    <OptionT value={range.nameRange}>{range.nameRange}</OptionT>
                  );
                })}
              </SelectT>
              {errors?.range?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>

          <div className="form-campo">
            <label className="label-campo">Porcentaje:</label>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputNumberT
                rules={{ required: true }}
                status={errors?.percent?.type === "required" ? "error" : ""}
                min="1"
                max="100"
                name="percent"
                control={control}
              ></InputNumberT>
              {errors?.percent?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Fecha de expiración:</label>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DatePickerT2
                status={
                  errors?.expirationDate?.type === "required" ||
                  errors?.expirationDate?.type === "checkDate"
                    ? "error"
                    : ""
                }
                rules={{
                  required: true,
                  validate: {
                    checkDate: (fecha) => {
                      return moment().isSameOrBefore(moment(fecha));
                    },
                  },
                }}
                name="expirationDate"
                control={control}
              ></DatePickerT2>

              {errors?.expirationDate?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errors?.expirationDate?.type === "checkDate" && (
                <span style={{ color: "red" }}>Fecha no valida</span>
              )}
            </div>
          </div>

          <Button
            htmlType="submit"
            loading={props.loadingActions.editDiscountProductLoading}
          >
            Guardar descuento del producto
          </Button>
        </form>
      );
    } else if (eventTypeModal === "createDiscountProduct") {
      return (
        <form onSubmit={handleSubmit(createIndividualProductDiscount)}>
          <div className="form-campo">
            <label className="label-campo">Equipo:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                rules={{ required: true }}
                status={errors?.team?.type === "required" ? "error" : null}
                mode="multiple"
                placeholder="Seleccione el equipo para aplicar el descuento"
                name="team"
                control={control}
              >
                <OptionT value={"Frontend"}>Frontend</OptionT>
                <OptionT value={"Backend"}>Backend</OptionT>
                <OptionT value={"Designer"}>Diseño</OptionT>
                <OptionT value={"Seller"}>Ventas</OptionT>
                <OptionT value={"Support"}>Soporte</OptionT>
                <OptionT value={"Estudiantes"}>Estudiantes</OptionT>
                <OptionT value={"Mantenimiento"}> Mantenimiento</OptionT>
                <OptionT value={"Invitados"}> Invitados</OptionT>
                <OptionT value={"Otros"}> Otros</OptionT>
                <OptionT value={"Todos"}>Todos</OptionT> 
              </SelectT>

              {errors?.team?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Jerarquía:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SelectT
                rules={{ required: true }}
                mode="multiple"
                status={errors?.range?.type === "required" ? "error" : null}
                placeholder="Selecciona las jerarquías para aplicar los descuentos"
                name="range"
                control={control}
              >
                <OptionT value="Todos">Todos</OptionT>
                {props.rangeList.map((range) => {
                  return (
                    <OptionT value={range.nameRange}>{range.nameRange}</OptionT>
                  );
                })}
              </SelectT>
              {errors?.range?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>

          <div className="form-campo">
            <label className="label-campo">Porcentaje:</label>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputNumberT
                rules={{ required: true }}
                status={errors?.percent?.type === "required" ? "error" : null}
                min="1"
                max="100"
                name="percent"
                control={control}
              ></InputNumberT>
              {errors?.percent?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div className="form-campo">
            <label className="label-campo">Fecha de expiración:</label>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DatePickerT2
                status={
                  errors?.expirationDate?.type === "required" ||
                  errors?.expirationDate?.type === "checkDate"
                    ? "error"
                    : null
                }
                rules={{
                  required: true,
                  validate: {
                    checkDate: (fecha) => {
                      return moment().isSameOrBefore(moment(fecha));
                    },
                  },
                }}
                name="expirationDate"
                control={control}
              ></DatePickerT2>
              {errors?.expirationDate?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errors?.expirationDate?.type === "checkDate" && (
                <span style={{ color: "red" }}>Fecha no valida</span>
              )}
            </div>
          </div>
          <Button
            htmlType="submit"
            loading={props.loadingActions.createDiscountProductLoading}
          >
            Guardar descuento
          </Button>
        </form>
      );
    }
  };
  const columns = [
    {
      title: "Fecha de expiración",
      key: "expirationDate",
      dataIndex: "expirationDate",
      render: (_, record) => {
        let expirationDateFormatted = moment(record.expirationDate).format(
          "YYYY-MM-DD"
        );
        return <Space>{expirationDateFormatted}</Space>;
      },
    },
    {
      title: "Porcentaje",
      key: "percent",
      dataIndex: "percent",
    },
    {
      title: "Rango",
      key: "range",
      dataIndex: "range",
      render: (_, record) => {
        return record.range
          .toString()
          .split(",")
          .map((rangeValue) => {
            return <Tag>{rangeValue} </Tag>;
          });
      },
    },
    {
      title: "Equipo",
      key: "team",
      dataIndex: "team",
      render: (_, record) => {
        return record.team
          .toString()
          .split(",")
          .map((teamValue) => {
            return <Tag>{teamValue} </Tag>;
          });
      },
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Space>
            <Button
              onClick={() => {
                deleteDiscountByProduct(record.idDiscount, props.product._id);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Button
              onClick={() => {
                setEventTypeModal("editDiscountProduct");
                showModal(record);
              }}
            >
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    loadDiscountsByProduct(props.product._id);
  }, []);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Table
        dataSource={discountList}
        loading={loading}
        columns={columns}
      ></Table>
      <Button
        type="primary"
        onClick={() => {
          setEventTypeModal("createDiscountProduct");

          showModal();
        }}
      >
        Agregar descuento al producto
      </Button>
      <Modal
        title={
          eventTypeModal === "createDiscountProduct"
            ? "Agregar descuento para el producto"
            : eventTypeModal === "editDiscountProduct"
            ? "Editar descuento del producto"
            : ""
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        {ShowingModalContentDiscount()}
      </Modal>
    </div>
  );
}

export default DiscountsByProductList;

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ApiTienda } from "../API/ConfigApi";


function RutasPrivadas({ children }) {



  return localStorage.getItem("token") ? children : <Navigate to="/login" />;
}

export default observer(RutasPrivadas);

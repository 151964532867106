import { Button, notification } from "antd";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import { InputT } from "../../components/FormularioTeclas";
import RangesList from "./RangesList";

function CreateRange() {
  const [rangeList, setRangeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingActions, setLoadingActions] = useState({
    createRangeLoading: false,
    editRangeLoading: false,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const createRange = (rangeValue) => {
    setLoadingActions((loadingActions) => ({
      ...loadingActions,
      createRangeLoading: true,
    }));
    ApiTienda(
      "admin",
      {
        name: rangeValue.valueRange,
      },
      "createRange"
    ).then((resp) => {
      if (resp.success === true) {
        notification["success"]({
          message: "Jerarquía creada",
        });
      } else {
        notification["error"]({
          message: "No se pudo crear la jerarquía",
        });
      }
      setLoadingActions((loadingActions) => ({
        ...loadingActions,
        createRangeLoading: false,
      }));
      loadRanges();
      reset();
    });
  };

  const loadRanges = () => {
    setLoading(true);
    ApiTienda("admin", {}, "getRanges").then((resp) => {
      setRangeList(resp.ranges);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadRanges();
  }, []);
  return (
    <div>
      <div style={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          margin:"10px 20px 10px 0px",
          padding: "10px 0 10px 0",
        }}>
        <h2 style={{ textAlign: "center" }}>Crear jerarquía</h2>
        <form
          onSubmit={handleSubmit(createRange)}
          style={{ marginBottom: "15px" }}
        >
          <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ margin: "0 15px 0 0" }}>Nombre:</p>
              <InputT
                ancho="300px"
                placeholder="Ingrese el nombre de la jerarquía"
                name="valueRange"
                status={errors?.valueRange?.type === "required" ? "error" : ""}
                control={control}
                rules={{ required: true }}
              ></InputT>
            <Button
              style={{ marginLeft: "20px" }}
              htmlType="submit"
              loading={loadingActions.createRangeLoading}
            >
              Guardar
            </Button>
            </div>
          </div>
        </form>
        {errors?.valueRange?.type === "required" && (
          <p style={{ color: "red",textAlign:"center" }}>Campo requerido</p>
        )}
      </div>

      <div>
        <h2 style={{ textAlign: "center" }}>Lista de jerarquías</h2>
        <RangesList
          rangeList={rangeList}
          loadRanges={loadRanges}
          loading={loading}
          setLoading={setLoading}
          loadingActions={loadingActions}
          setLoadingActions={setLoadingActions}
        />
      </div>
    </div>
  );
}

export default CreateRange;

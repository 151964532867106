import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Switch,
  InputNumber,
} from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";

const { RangePicker } = DatePicker;

const InputT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input
              hidden={props.hidden || false}
              style={{
                width: props.ancho,
                backgroundColor: props.backgroundColor,
                borderRadius: props.borderRadius,
              }}
              type={props.type}
              status={props.status}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};

const InputPasswordT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input.Password
              hidden={props.hidden || false}
              style={{
                width: props.ancho,
                backgroundColor: props.backgroundColor,
                borderRadius: props.borderRadius,
              }}
              type={props.type}
              status={props.status}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};

const TextAreaT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input.TextArea
              style={{ width: props.ancho }}
              status={props.status}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const InputNumberT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <InputNumber
              disabled={props.disabled}
              hidden={props.hidden || false}
              style={{ width: props.ancho, borderRadius: props.borderRadius }}
              status={props.status}
              value={value}
              min={props.min}
              max={props.max}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};

const InputSearchT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input.Search
              disabled={props.disabled}
              hidden={props.hidden || false}
              style={props.style}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const CheckboxT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Checkbox
            hidden={props.hidden || false}
            checked={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          />
        );
      }}
    />
  );
};
const SwitchT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Switch
            hidden={props.hidden || false}
            valuePropName="checked"
            checked={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          />
        );
      }}
    />
  );
};
const RadioGroupT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Radio.Group
            value={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          >
            {props.children}
          </Radio.Group>
        );
      }}
    />
  );
};
const RadioT = (props) => {
  return <Radio value={props.valor} hidden={props.hidden || false} />;
};

const SelectT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Select
              placeholder={props.placeholder}
              status={props.status}
              style={{ width: props.ancho }}
              value={value}
              onSelect={props.onSelect}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
              showSearch={props.showSearch || false}
              mode={props.mode}
            >
              {props.children}
            </Select>
          </>
        );
      }}
    />
  );
};
const OptionT = (props) => {
  return (
    <>
      <Option value={props.value} hidden={props.hidden || false}>
        {props.children}
      </Option>
    </>
  );
};
const DatePickerT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange } }) => {
        return (
          <>
            <DatePicker
              format={props.format}
              status={props.status}
              style={{ width: props.ancho }}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
              picker={props.picker}
            />
          </>
        );
      }}
    />
  );
};
const DatePickerT2 = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {value ? (
              <>
                <DatePicker
                  status={props.status}
                  key={props.key}
                  style={props.style}
                  value={moment(value)}
                  format={props.format}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange(e);
                    }
                    onChange(e);
                  }}
                  picker={props.picker}
                />
              </>
            ) : (
              <>
                <DatePicker
                  status={props.status}
                  key={props.key}
                  style={props.style}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange(e);
                    }
                    onChange(e);
                  }}
                  picker={props.picker}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};
const RangePickerT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange } }) => {
        return (
          <>
            <RangePicker
              status={props.status}
              style={{ width: props.ancho }}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const RangePickerT2 = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {value ? (
              <>
                <RangePicker
                  status={props.status}
                  value={[moment(value[0]), moment(value[1])]}
                  style={props.style}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange(e);
                    }
                    onChange(e);
                  }}
                />
              </>
            ) : (
              <>
                <RangePicker
                  style={props.style}
                  status={props.status}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange(e);
                    }
                    onChange(e);
                  }}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};
const ButtonT = (props) => {
  return (
    <Button
      type={props.type}
      loading={props.loading}
      disabled={props.disabled}
      onClick={props.onClick}
      htmlType={props.htmlType}
      hidden={props.hidden || false}
      style={props.style}
    >
      {props.children}
    </Button>
  );
};

export {
  InputT,
  InputPasswordT,
  CheckboxT,
  SwitchT,
  RadioGroupT,
  RadioT,
  SelectT,
  OptionT,
  DatePickerT,
  DatePickerT2,
  RangePickerT,
  RangePickerT2,
  ButtonT,
  InputNumberT,
  TextAreaT,
};

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

function RutasPublicas() {
    return !localStorage.getItem("token") ? (
        <Outlet />
      ) : (
        <Navigate to="/tienda/*"></Navigate>
      );
}

export default RutasPublicas
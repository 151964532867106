import { Button, Input, InputNumber } from "antd";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import FooterAdmin from "../../components/FooterAdmin";
import { InputNumberT } from "../../components/FormularioTeclas";

function SendNumber(props) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useFormContext();

 
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "250px",
            display: "flex",
            flexDirection: "column",
            padding: "25px 20px",
            borderRadius: "10px",
            border: "1px solid #D8D8D8",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: 900,
              fontSize: "30px",
              color: "#7d07f3",
            }}
          >
            Recupera tu contraseña
          </h2>
          <p style={{ textAlign: "center" }}>
            Ingresa tu número telefónico para recibir el código de recuperación
          </p>

          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
          <form onSubmit={handleSubmit(props.recoveryPasswordUser)}>
            <InputNumberT
              status={errors?.cellphone?.type === "required" ? "error" : ""}
              control={control}
              name={"cellphone"}
              ancho="100%"
              borderRadius="7px"
              rules={{ required: true }}
            ></InputNumberT>

            {errors?.cellphone?.type === "required" && (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span style={{ color: "red" }}>Campo requerido</span>
              </div>
            )}
            <div>
              <Button
                style={{
                  width: "100%",
                  margin: "15px 0 0 0",
                  borderRadius: "7px",
                  backgroundColor: "#7d07f3",
                  color: "#fff",
                }}
                htmlType="submit"
                loading={props.loadingAction.loadingNumber}
              >
                Enviar número
              </Button>
            </div>
          </form>
        </div>
      </div>

      <FooterAdmin />
    </div>
  );
}

export default SendNumber;

import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { InputPasswordT, InputT } from "../../components/FormularioTeclas";
import { Button, Spin } from "antd";
import { ApiTienda } from "../../API/ConfigApi";

import { Link, useNavigate } from "react-router-dom";
import { setIsSuperAdmin, setToken } from "../../store/store";
import { observer } from "mobx-react-lite";
import logo from "../../assets/logo_blanco.jpg";

function LoginPage() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [failLogin, setFailLogin] = useState(false);
  const [isUserActive, setIsUserActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const loginUser = (valuesUser) => {
    setLoading(true);
    setIsUserActive(true);
    setFailLogin(false);
    ApiTienda(
      "loginAdmin",
      {
        ...valuesUser,
      },
      "loginAdmin"
    )
      .then((resp) => {
        if (resp.token) {
          localStorage.setItem("token", resp.token);
          navigate("/tienda/");
        } else {
          if (resp.active === false && resp.active !== undefined) {
            setFailLogin(true);
            setIsUserActive(false);
          }
          setFailLogin(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // backgroundColor:"red"
      }}
    >
      <div
        style={{
          width: "300px",
          padding: "25px 20px",
          // boxShadow: "1px 1px 10px #dbdbdb",
          borderRadius: "5px",
          // backgroundColor:"green"
        }}
      >
        <div
          style={{
            marginBottom: "30px",
          }}
        >
          {" "}
          <img src={logo} width="100%" alt="Logo" />
          <h2
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: "20px",
            }}
          >
            Inicio de sesión{" "}
          </h2>
          <hr />
        </div>
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Spin></Spin>
          </div>
        )}

        <form onSubmit={handleSubmit(loginUser)}>
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <label>Celular:</label>
            <InputT
              borderRadius="5px"
              name="cellphone"
              status={errors?.cellphone?.type === "required" ? "error" : ""}
              placeholder="Ingresa tu celular"
              control={control}
              rules={{ required: true }}
            />
            {errors?.cellphone?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>

          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <label>Contraseña:</label>
            <InputPasswordT
              borderRadius="5px"
              status={errors?.password?.type === "required" ? "error" : ""}
              name="password"
              placeholder="Ingresa tu contraseña"
              type="password"
              control={control}
              rules={{required:true}}
            />
              {errors?.password?.type === "required" && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>

          {failLogin && (
            <span
              style={{
                color: "red",
                marginBottom: "15px",
              }}
            >
              {isUserActive === false
                ? "Usuario no está activado"
                : " Error al iniciar sesión, credenciales incorrectas."}
            </span>
          )}
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <Button
              htmlType="submit"
              style={{
                backgroundColor: "#7d07f3",
                borderRadius: "20px",
                width: "100%",
                borderWidth: 0,
                fontWeight: "bold",
              }}
              type="primary"
              loading={loading}
            >
              Iniciar sesión
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            {" "}
            <Link to="/recovery-password" style={{ color: "#7d07f3" }}>
              Recuperar contraseña
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default observer(LoginPage);

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Space, Table } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiTienda } from "../../API/ConfigApi";
import {
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "../../components/FormularioTeclas";
import { setSales, store } from "../../store/store";

function SalesList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantityOfSales, setQuantityOfSales] = useState();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm();

  const loadSalesOfProducts = (currentPage) => {
    setLoading(true);
    ApiTienda(
      "admin",
      {
        page: currentPage === undefined ? 1 : currentPage,
        newAction: "getAllInventory",
      },
      "historySales"
    ).then((resp) => {
      setLoading(false);
      setSales(resp.sales);
    });
  };

  const getQuantityOfSalesOfProduct = () => {
    ApiTienda(
      "admin",
      {
        newAction: "countDocuments",
      },
      "historySales"
    ).then((resp) => {
      setQuantityOfSales(resp.cantidad);
    });
  };

  const generateRefundsOfProducts = (valuesRefunds) => {
    Modal.confirm({
      title: "¿Quieres realizar la devolución del producto?",
      onCancel: () => {},
      confirmLOading: true,
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk: () => {
        setLoadingAction(true);
        ApiTienda(
          "admin",
          {
            newAction: "generateDevolutions",
            idCompra: getValues("idSaleSelected"),
            units: valuesRefunds.quantityToReturn,
            returnBodega: valuesRefunds.option,
          },
          "historySales"
        ).then((resp) => {
          if (resp.success === true) {
            notification["success"]({
              message: "Devolución exitosa",
            });
          } else {
            notification["error"]({
              message: "No se pudo realizar la devolución",
            });
          }
          loadSalesOfProducts(currentPage);
          setLoadingAction(false);
          setIsModalOpen(false);
          reset();
        });
      },
    });
  };

  useEffect(() => {
    getQuantityOfSalesOfProduct();
    loadSalesOfProducts();
  }, []);

  const columns = [
    {
      title: "Usuario",
      dataIndex: "nameUser",
      key: "nameUser",
    },
    {
      title: "Celular",
      dataIndex: "cellUser",
      key: "cellUser",
    },
    {
      title: "Producto",
      dataIndex: "nameProduct",
      key: "nameProduct",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Precio",
      dataIndex: "discountPrice",
      key: "discountPrice",
    },
    {
      title: "Precio Total",
      dataIndex: "priceTotal",
      key: "priceTotal",
    },

    {
      title: "% Descuento",
      dataIndex: "discountPercent",
      key: "discountPercent",
    },
    {
      title: "# Devoluciones",
      dataIndex: "devolucion",
      key: "devolucion",
    },
    {
      title: "Acción",
      dataIndex: "accion",
      key: "accion",
      render: (_, record) => {
        return (
          <Space size="medium">
            {record.today ? (
              <Button
                onClick={() => {
                  setValue("idSaleSelected", record._id);
                  setValue("quantity", record.quantity);
                  setIsModalOpen(true);
                }}
              >
                Hacer devolución
              </Button>
            ) : (
              "No disponible"
            )}
          </Space>
        );
      },
    },
  ];
  return (
    <div style={{paddingTop:"30px"}}>
      <h2 style={{textAlign:"center"}}>Lista de ventas </h2>
      <div
        style={{
          marginRight: "20px",
          borderTopRightRadius: "10px",
          overflow: "hidden",
          borderTopLeftRadius: "10px",
        }}
      >
        <Table
          loading={loading}
          dataSource={store.sales}
          columns={columns}
          pagination={{
            current: currentPage,
            showSizeChanger: false,
            pageSize: 10,
            total: quantityOfSales,
            onChange: (page) => {
              loadSalesOfProducts(page);
              setCurrentPage(page);
            },
          }}
        />
      </div>
      <Modal
        title="Hacer devolucion del producto"
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          reset();
          setIsModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(generateRefundsOfProducts)}>
          <div
            className="form-campo"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="label-campo">Desea devolver al inventario:</label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SelectT
                placeholder="Escoge una opcion"
                name="option"
                status={errors?.option?.type === "required" ? "error" : ""}
                control={control}
                rules={{ required: true }}
              >
                <OptionT value="Si">Si</OptionT>
                <OptionT value="No>">No</OptionT>
              </SelectT>
              {errors?.option?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
            </div>
          </div>
          <div
            className="form-campo"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="label-campo">Cantidad para devolver:</label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <InputNumberT
                name="quantityToReturn"
                status={
                  errors?.quantityToReturn?.type === "required" ||
                  errors?.quantityToReturn?.type === "min" ||
                  errors?.quantityToReturn?.type === "max"
                    ? "error"
                    : ""
                }
                control={control}
                rules={{
                  required: true,
                  min: 0,
                  max: Number(getValues("quantity")),
                }}
              ></InputNumberT>
              {errors?.quantityToReturn?.type === "required" && (
                <span style={{ color: "red" }}>Campo requerido</span>
              )}
              {errors?.quantityToReturn?.type === "min" && (
                <span style={{ color: "red" }}>
                  No se puede devolver una cantidad menor a 0
                </span>
              )}
              {errors?.quantityToReturn?.type === "max" && (
                <span style={{ color: "red" }}>Cantidad no permitida</span>
              )}
            </div>
          </div>

          <Button htmlType="submit" loading={loadingAction}>
            Crear devolución
          </Button>
        </form>
      </Modal>
    </div>
  );
}

export default observer(SalesList);
